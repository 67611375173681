import {
  Component,
  NgZone,
  OnDestroy,
  ViewChild
} from '@angular/core';

import {
  NavController,
  Platform,
  ModalController,
  IonRouterOutlet,
  AlertController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import {
  distinctUntilChanged,
  takeUntil,
  filter,
  first,
  tap,
  switchMapTo,
  take,
} from 'rxjs/operators';
import {
  Subject,
  BehaviorSubject
} from 'rxjs';
import { PinService } from 'src/services/pin.service';
import { NotificationService } from 'src/services/notification.service';
import { UpdateService } from 'src/services/update.service';
import { ModulesService } from '../services/modules.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NetworkService } from 'src/services/network.service';

import {
  ShareService
} from 'src/services/share.service';
import {
  ChatService
} from 'src/services/chat.service';

import {
  NavigationService
} from 'src/services/navigation.service';
import { UserService } from 'src/services/user.service';
import { VideoModalService } from 'src/services/video-modal.service';
import { MenuService } from 'src/services/menu-service';
import { GuttmannService } from 'src/services/guttmann.service';
import { LoadingService } from 'src/services/loading.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  public appPages = [{
    title: 'Home',
    url: '/home',
    icon: 'home'
  },
  {
    title: 'List',
    url: '/list',
    icon: 'list'
  }
  ];
  public onDestroy$: Subject<void> = new Subject();
  public appInit = false;
  private innerIsLogIn$: BehaviorSubject<any> = new BehaviorSubject(false);
  public startWithoutConn = false;

  public showAppBanner = false;

  constructor(
    public platform: Platform,
    public navCtrl: NavController,
    public auth: AuthService,
    public menuService: MenuService,
    public translate: TranslateService,
    public pinService: PinService,
    public moduleService: ModulesService,
    public updateService: UpdateService,
    public notificationService: NotificationService,
    public networkService: NetworkService,
    public shareService: ShareService,
    public chatService: ChatService,
    public modalCtrl: ModalController,
    public navService: NavigationService,
    public userService: UserService,
    public videoModalService: VideoModalService,
    private _ngZone: NgZone,
    private router: Router
  ) {
    // this.navCtrl.navigateRoot('blank');



    // dark mode detection
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    // Add or remove the "dark" class based on if the media query matches
    this.toggleDarkTheme(prefersDark.matches);
    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));


    this.initializeApp();
  }

  toggleDarkTheme(darkMode) {
    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      StatusBar.setStyle({
        style: Style.Light
      });
    }
    // console.log("MODE", darkMode);
    // document.body.classList.toggle('dark', shouldAdd);
  }

  async initializeApp() {
    //const firebaseApp = initializeApp(environment.firebase);
    this.translate.setDefaultLang('ca');
    this.translate.use('ca');

    await this.platform.ready();

    this.navService.init(this.routerOutlet);
    this.videoModalService.init();
    // this.subscribeAuth();

    if (!this.platform.is('capacitor')) {
      this.router.events.pipe(
        filter((event) => event instanceof RoutesRecognized),
        take(1)
      ).subscribe((event) => {
        // console.log("EVENT URL =>", event['url']);
        if (event['url'].includes('login?code=')) {
            let redirectToApp = function() {  
              window.location.replace(`mhce://${event['url']}`);
              // window.location.replace(`mhce:/${event['url']}`);
            };
            window.onload = redirectToApp;
        }
      });
    }

    await this.subscribeAuth();

    this.auth.init();

    if (this.platform.is('mobileweb')) {
      this.showAppBanner = true;
    }

    /**
     * Iniciar quan es fa redirecció a l'app.
     */
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._ngZone.run(async () => {
        let slug = event.url;
        if (slug.includes(environment.SSO_REDIRECT))
          slug = slug.split(environment.SSO_REDIRECT+"/").pop();
        else
          slug = slug.split("mhce://").pop();

        if (slug.includes('login?code=')) {
          await this.navCtrl.navigateRoot(slug);
          this.auth.init();
        }
        else if (slug.includes('login/?code=')) {
          let code = slug.split('login/').pop();
          await this.navCtrl.navigateRoot("login"+code);
          this.auth.init();
        }
      });
    });
  }

  /**
   * Stream que es subscriu a l'estat d'autenticació i fa canviar l'estat de l'aplicació
   * i altres variables que són de l'inici i depenen de l'autenticació com els share states
   */
  async subscribeAuth() {
    this.auth.getAuthenticationState().pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      // Mirem l'estat d'autenticació
      tap(async (isAuth) => {
        await this.navCtrl.navigateRoot('blank');
        if (!isAuth) {
          await this.navCtrl.navigateRoot('login');
          this.innerIsLogIn$.next(false);
        }
      }),
      // Només mirem el PIN si auth = true
      filter(x => x),
      // Si el pin és true passem a la següent fase
      switchMapTo(this.pinService.isAuthenticated$.pipe(
        filter(x => x),
        distinctUntilChanged()
      )),
      // Ens assegurem de tenir orgUserInfo
      switchMapTo(this.userService.orgUserInfo$.pipe(
        filter(x => x),
        first()
      )),
      // Si tenim una nova org canviem landing
      switchMapTo(this.userService.org$.pipe(
        filter(x => x),
        distinctUntilChanged((a, b) => a.id === b.id)
      )),
      // Carreguem els mòduls
      tap(async () => await this.menuService.loadModules()),
      // Agafem la landing page el primer cop (No cada cop que es fa refresh dels mòduls)
      // i naveguem. Sempre hi haura landing pq o bé serà el que rebem del backend o bé precalculada
      switchMapTo(this.moduleService.landingPage$.pipe(
        distinctUntilChanged(),
        filter(x => x),
        // first(),
        tap(async (landingPage) => {
          const url = await this.getUrlFromLanding(landingPage);
          await this.navCtrl.navigateRoot(url);
          this.innerIsLogIn$.next(true);
        })
      )),
      // Si rebem un share event agafem l'element a compartir i naveguem a la vista de compartir.
      // Després resetejem el share a null per no procesar-lo quan es torni a cridar l'auth
      switchMapTo(this.shareService.shareEvent$.pipe(
        takeUntil(this.onDestroy$),
        filter(x => x),
        tap(async (shareEvent) => {
          this.chatService.shareObject = shareEvent;
          await this.navService.removeDetail('chat_module/list');
          try {
            // await this.navCtrl.navigateRoot('chat_module');
            this.shareService.shareEvent$.next(null);
          } catch (e) { console.log("ERROR SHARE 0", e) }
        })
      ))
    ).subscribe();
  }




  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private async getUrlFromLanding(landingPage) {
    const org = this.userService.getOrganization();
    const orgUserInfo = this.userService.orgUserInfo$.value;

    if (landingPage && landingPage.id) {
      return `module-navigation/${landingPage.id}`;
    }

    if (
      this.userService.getUser() && this.userService.getUser().profile && this.userService.getUser().profile.patient &&
      org && org.modules && org.modules.visits && orgUserInfo && orgUserInfo.modules && orgUserInfo.modules.visits) {
      return 'visits';
    }

    if (!org.modules.chat && org.modules.publicForm && orgUserInfo.modules && orgUserInfo.modules.publicForm)
      return 'public-form';

    if (!org.modules.chat && org.modules.secureCom && orgUserInfo.modules && orgUserInfo.modules.secureCom)
      return 'secure-com';

    // AFEGIR (si cal) MOCK NAVEGACIO
    // return 'workday-register'
    // return 'patients-dashboard'
    return 'chat_module';
  }

  async openAppOrStore() {
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    const appScheme = 'mhce://login';
    const iosAppStoreLink = 'itms-apps://apps.apple.com/app/6741067087'; // Direct iOS App Store
    const androidPlayStoreLink = 'market://details?id=com.mhce.app'; // Direct Android Play Store

    // Try to open the app
    window.location.href = isiOS ? iosAppStoreLink : appScheme;
    if (isiOS) return;

    // If app doesn't open, redirect to store
    const timeout = setTimeout(() => {
      window.location.href = androidPlayStoreLink;
    }, 1000);

    // Clear timeout if page visibility changes (app opened successfully)
    document.addEventListener('visibilitychange', () => {
      clearTimeout(timeout);
    }, { once: true });
  }

}