// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  overflow: hidden;
  position: relative;
}
.container img {
  margin: auto;
  display: flex;
  max-width: 200px;
  height: auto !important;
}
.container img.not-loaded {
  filter: blur(10px);
  cursor: pointer;
}

.load-button {
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 10px 20px;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 1.3em;
}

@media (max-width: 412px) {
  .container {
    position: relative;
  }
  .container img {
    max-width: 200px;
  }
}
@media (max-width: 320px) {
  .container {
    position: relative;
  }
  .container img {
    max-width: 175px;
  }
}
.download-spinner {
  margin: auto;
  position: absolute;
  top: 37%;
  left: 50%;
}

video {
  max-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/video-thumb/video-thumb.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;AACJ;AAAI;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;AAER;AADQ;EACI,kBAAA;EACA,eAAA;AAGZ;;AAEA;EACI,eAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,kBAAA;EACN;EAAM;IACI,gBAAA;EAEV;AACF;AACA;EACI;IACI,kBAAA;EACN;EAAM;IACI,gBAAA;EAEV;AACF;AAEA;EACI,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ","sourcesContent":[".container {\n    overflow: hidden;\n    position: relative;\n    img {\n        margin: auto;\n        display: flex;\n        max-width: 200px;\n        height: auto !important;\n        &.not-loaded {\n            filter: blur(10px);\n            cursor: pointer;\n        }\n    }\n}\n\n.load-button {\n    cursor: pointer;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    position: absolute;\n    padding: 10px 20px;\n    border-radius: 25%;\n    background-color: rgba(0, 0, 0, 0.4);\n    font-size: 1.3em;\n}\n\n@media (max-width: 412px) {\n    .container {\n        position: relative;\n        img {\n            max-width: 200px;\n        }\n    }\n}\n@media (max-width: 320px) {\n    .container {\n        position: relative;\n        img {\n            max-width: 175px;\n        }\n    }\n}\n\n.download-spinner {\n    margin: auto;\n    position: absolute;\n    top: 37%;\n    left: 50%;\n}\n\nvideo {\n    max-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
