import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from './environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: 'https://login.microsoftonline.com/44a59773-ac9e-489a-bb61-62168c24f8b0/v2.0',

  // URL of the SPA to redirect the user to after login
  // redirectUri: `${environment.HOST_URL}/login`,
  redirectUri: `${environment.SSO_REDIRECT}/login`,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: '33c6e2ad-dbdf-44f8-b3bb-9e020ed74fce',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile email offline_access',

  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  sessionChecksEnabled: true
};
