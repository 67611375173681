import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ColorGenerator } from '../text-img/color-generator';
import { ReceiptStatusUtil } from '../receipt-status/receipt-status.component';
import { Platform, ActionSheetController, ToastController, AlertController, ModalController, IonRouterOutlet, PopoverController, IonSlides } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from 'src/services/chat.service';
import { FileService } from 'src/services/file.service';
import { DatePipe, KeyValue } from '@angular/common';
import { NavigationService } from 'src/services/navigation.service';
import { VideoService } from 'src/services/video.service';
import { VideoModalService } from 'src/services/video-modal.service';
import { AudioService } from 'src/services/audio.service';
import { UserService } from 'src/services/user.service';
import { ReactionIconsComponent } from '../reaction-icons/reaction-icons.component';
import { FirebaseService } from 'src/services/firebase.service';
import { ReactionsInfoModal } from '../reactions-info-modal/reactions-info-modal.component';
import { MessageInfoComponent } from '../message-info/message-info.component';
import { Clipboard } from '@capacitor/clipboard';
import { PopoverService } from '@atheneasolutions/ion-popover-component';
import { PopoverService as PopoverService2 } from 'src/services/popover.service';


@Component({
  selector: 'chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
})
export class ChatBubbleComponent implements OnInit, AfterViewInit {

  @Input() public fromMe: boolean = true;
  @Input() public chatKey: string;
  @Input() public viewOnly:boolean = false;
  @Input() public chat;
  @Input() public origin: string;
  @Input() public showName: boolean;
  @Input() public modulesChat = false; //chat from modules component
  @Input() public isSaved = true; //chat from modules component

  @Input() public message;

  @Input() public position: string = 'right';
  @Input() public name: string | null = null;
  @Input() public failed: boolean;
  @Input() public isPatient: boolean = false;
  @Input()
  set setFirstLoading (load: boolean) {
    this.firstLoad = load;
  }

  @Output() public replyMessage: EventEmitter<any> = new EventEmitter();
  @Output() public closeSliding: EventEmitter<any> = new EventEmitter();

  // public messageDurationDays: number = 3974400000; // 46 days which filters 45  days
  public toast;
  public msgSelected: boolean = false;
  public _bubbleMouseOver: any[] = [];
  public namePrefix: string = "Usuari";
  public modal;
  public org;
  public isModalMessageOpen: boolean = false;

  public firstLoad;
  public playing = false;
  public player;
  @ViewChild('audio', { static: false }) public audio;
  public messageForReaction;
  reactionsInfoOpen = false;
  @ViewChild('reactionsSlider') slider: IonSlides;
  reactionArray = {};
  currentUserId;
  maxEmojis = 5;
  messageOptionsDisabledClick = true;

  constructor(public platform: Platform,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    public us: UserService,
    public alertController: AlertController,
    public tr: TranslateService,
    public toastCtrl: ToastController,
    public chatService: ChatService,
    public datePipe: DatePipe,
    public fileService: FileService,
    public navService: NavigationService,
    public videoService: VideoService,
    public audioService: AudioService,
    public videoModal: VideoModalService,
    public popoverCtrl: PopoverController,
    public fs: FirebaseService,
    public popoverService2: PopoverService2,
    public modalController: ModalController) { }

  ngOnInit() {
    this.currentUserId = this.fs.auth.currentUser.uid;
    this.org = this.us.getOrganization();
  }

  ngAfterViewInit() {
    if (this.platform.is('ios')) {
      if (this.audio) this.player = this.audio.nativeElement;
      if (this.player) {
        this.player.addEventListener("timeupdate", this.updateProgress, false);
      }
    }
  }

  get receiptStatus() {
    return ReceiptStatusUtil.getMessageStatus(this.message);
  }

  sortZeroOut = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);
  }

  //noinspection JSMethodCanBeStatic
  processHTML(escapedMessage: any): string {
    if (escapedMessage) {
      return escapedMessage.replace(/&gt;/g, '>').replace(/&lt;/g, '<');
    }
    return escapedMessage;
  }

  async bubbleClicked(event: Event, message: any) {
  }

  async presentMessageOptions(e: Event, isHold: boolean) {
    this.isModalMessageOpen = true;
    this.msgSelected = true;
    let messageOptionsItems = [
      {
        text: this.tr.instant('messages.options.reply'),
        icon: 'arrow-undo',
        role: 'emitReplyMessage',
        if: (!this.message.deleted && !this.modulesChat && !this.chatService.chatToolbarDisabled(this.origin, this.chat))
      },
      {
        text: this.tr.instant('messages.options.forward'),
        icon: 'arrow-redo',
        role: 'forwardMessage',
        if: (!this.message.deleted && !this.modulesChat && !this.chatService.chatToolbarDisabled(this.origin, this.chat))
      },
      {
        text: this.tr.instant('messages.options.delete'),
        icon: 'trash',
        role: 'presentDeleteAlert',
        if: (!this.message.deleted && this.position === 'right' && !this.isPatient && this.org.params && this.org.params.chat && this.org.params.chat.deleteMessage)
      },
      {
        text: this.tr.instant('messages.options.priority'),
        icon: 'alert',
        role: 'presentPriorityAlert',
        if: (!this.message.deleted && !this.isPatient && this.position === 'right' && !this.modulesChat)
      },
      {
        text: this.tr.instant('messages.options.save_message'),
        icon: 'star',
        role: 'saveMessage',
        if: (!this.message?.saveMsg || this.message?.saveMsg == undefined || !this.message?.saveMsg[this.us?.getUser()?.id] && !this.isPatient && this.position === 'right' && !this.modulesChat)
      },
      {
        text: this.tr.instant('messages.options.delete_save_message'),
        icon: 'star-outline',
        role: 'notSaveMessage',
        if: ((this.message?.saveMsg && this.message?.saveMsg[this.us?.getUser()?.id]) != undefined && !this.isPatient && this.position === 'right' && !this.modulesChat)
      },
      {
        text: this.tr.instant('messages.options.info'),
        icon: 'people',
        role: 'presentMessageInfo',
        if: (!this.message.deleted && !this.isPatient && this.position === 'right' && !this.modulesChat)
      },
      {
        text: this.tr.instant('messages.options.copy'),
        icon: 'copy',
        role: 'copy'
      }
    ]
    const popoverRes = await this.popoverService2.presentPopover(e, messageOptionsItems, isHold);
    switch (popoverRes.role) {
      case 'emitReplyMessage':
        this.emitReplyMessage();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'forwardMessage':
        this.forwardMessage();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'presentDeleteAlert':
        this.presentDeleteAlert();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'presentPriorityAlert':
        this.presentPriorityAlert();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'saveMessage':
        this.saveMsg();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'notSaveMessage':
        this.deleteSaveMsg();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'presentMessageInfo':
        this.presentMessageInfo();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'copy':
        this.copy();
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      case 'reaction':
        let reaction = popoverRes.data;
        if (reaction) this.chatService.addReaction(this.message, this.chat, this.origin, reaction);
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
      default:
        this.isModalMessageOpen = false;
        this.mouseOut(this.message);
        break;
    }

    this.isModalMessageOpen = false;
    this.mouseOut(this.message);
    this.msgSelected = false;
  }

  async forwardMessage() {
    this.chatService.forwardMsg = this.message;
    await this.navService.removeDetail();
    await this.chatService.toastForwardMsg();
  }

  public async presentDeleteAlert() {
    let translations = [];
    await this.tr.get(['messages.options', 'general']).forEach(tr => { translations = tr });
    let title = translations['messages.options'].delete_warning;
    let text = translations['messages.options'].delete_text;

    let alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [{
        text: translations['general'].yes,
        handler: async () => {
          await this.chatService.deleteMessage(this.message, this.chat, this.origin);
        }
      },
      {
        text: translations['general'].no,
        role: 'destructive'
      }]
    });
    await alert.present();
    await alert.onWillDismiss();
    this.msgSelected = false;
  }

  public async presentPriorityAlert() {
    let translations = [];
    await this.tr.get(['messages.options', 'general']).forEach(tr => { translations = tr });
    let title = translations['messages.options'].priority_send;
    let subTitle = translations['messages.options'].priority_msg_c;
    if (this.origin === 'group') {
      subTitle = translations['messages.options'].priority_msg_g;
    }
    let buttons = [];


    if (this.message.priority === true) {
      title = translations['messages.options'].warning;
      subTitle = translations['messages.options'].priority_exists;
      buttons.push({
        text: translations['general'].ok,
        role: 'destructive'
      });
    } else if (this.message.seen) {
      title = translations['messages.options'].warning;
      subTitle = translations['messages.options'].priority_seen_c;
      if (this.origin === 'group') {
        subTitle = translations['messages.options'].priority_seen_g;
      }
      buttons.push({
        text: translations['general'].yes,
        handler: async () => {
          await this.chatService.markMessageAsPriority(this.message, this.chat, this.origin);
        }
      });
      buttons.push({
        text: translations['general'].no,
        role: 'destructive'
      });
    } else {
      if (this.message.delivered) {
        title = translations['messages.options'].warning;
        subTitle = translations['messages.options'].priority_delivered_c;
        if (this.origin === 'group') {
          subTitle = translations['messages.options'].priority_delivered_g;
        }
      }
      buttons.push({
        text: translations['general'].yes,
        handler: async () => {
          try {
            await this.chatService.notifyMessagePriority(this.message, this.chat, this.origin);
            await this.chatService.markMessageAsPriority(this.message, this.chat, this.origin);
          } catch (e) {
            // console.log(e)
            await this.presentToast(translations['messages.options'].priority_error);
          }
        }
      });
      buttons.push({
        text: translations['general'].no,
        role: 'destructive'
      });
    }
    let alert = await this.alertController.create({
      header: title,
      subHeader: subTitle,
      buttons
    });
    await alert.present();
    await alert.onWillDismiss();
    this.msgSelected = false;
  }

  saveMsg() {
    this.chatService.saveMsg(this.chat, this.message, this.origin);
  }

  deleteSaveMsg() {
    this.chatService.deleteSaveMsg(this.chat, this.message, this.origin);
  }

  async emitReplyMessage(as?) {
    if (as) await as.onDidDismiss();

    let replyMsg = Object.assign({}, this.message);
    if (!replyMsg.fromName) {
      replyMsg.fromName = this.name;
    }
    this.replyMessage.emit(replyMsg);
    this.msgSelected = false;
  }

  public async presentMessageInfo() {
    this.modal = await this.modalController.create({
      component: MessageInfoComponent,
      componentProps: {
        _message: this.message,
        _group: this.chat
      },
      swipeToClose: true
    });
    await this.modal.present();
  }

  async copy() {
    let translations = [];
    await this.tr.get(['messages.options', 'general.cancel', 'general.picture', 'general.audio', 'general.voiceNote']).forEach(tr => translations = tr);
    let subHeader = '';
    let html = this.message.content;
    var div = document.createElement("div");
    div.innerHTML = html;
    subHeader = div.innerText;
    if (this.message.file && this.fileService.isImage(this.message.file.type)) {
      subHeader = "📷" + " " + translations["general.picture"];
    } else if (this.message.file && this.fileService.isAudio(this.message.file.type)) {
      subHeader = "🎙" + " " + (this.message.file.voice ? translations["general.voiceNote"] : translations["general.audio"]);
    }

    await Clipboard.write({ string: div.innerText });
    this.presentToast(translations['messages.options'].copied);
    this.msgSelected = false;

    if (subHeader.length > 15) {
      subHeader = '"' + subHeader.substring(0, 15) + "..." + '"';
    } else {
      subHeader = '"' + subHeader + '"';
    }
  }

  public async presentToast(msg: string) {
    if (this.toast) {
      await this.toast.dismiss();
    }
    this.toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await this.toast.present();
  }

  mouseOver(message) {
    if (!this.mobileView()) {
      this.messageForReaction = message;
      this._bubbleMouseOver[message.id] = true;
    }
  }

  mouseOut(message) {
    if (!this.mobileView()) {
      if (!this.isModalMessageOpen)
      this._bubbleMouseOver[message.id] = false;
    }
  }

  isMouseOver(message) {
    if (!this.mobileView()) {
      return this._bubbleMouseOver[message.id] === true;
    }
  }

  audioAction() {
    if (this.player) {
      if (this.playing) {
        this.player.pause();
        this.playing = false;
      } else {
        this.player.play();
        this.playing = true;
      }
    }
  }

  audioTimeFormat(time) {
    return this.datePipe.transform(Math.floor(time) * 1000, 'm:ss')
  }

  updateProgress() {
    if (!this.player) return;
    var progress = document.getElementById("progress" + this.message.id);
    var value = 0;
    if (this.player.currentTime > 0) {
      value = Math.floor((100 / this.player.duration) * this.player.currentTime);
    }
    progress.style.width = value + "%";

    if (value === 100) this.playing = false;
  }

  settingsButton(message) {
    if (this.chatService.chatToolbarDisabled(this.origin, this.chat)) return false;
    return !this.mobileView() && !this.failed;
  }

  getColor(position, message) {
    if (position === 'left' && message && message.from) {
      return ColorGenerator.getTextColor(message.from);
    }
  }

  mobileView(): boolean {
    return (this.platform.is('capacitor') || this.platform.is('android') || this.platform.is('ios'));
  }


  async answerCall(message) {
    let params = {
      'join': true,
      'room': message,
      'callMode': (message.call && message.call.mode === 'call') ? true : false
    }
    await this.audioService.stopRingTone(true);
    if (this.videoService.disabledVideoWebView) await this.videoService.openExternal(params);
    else await this.videoModal.openVideo(params);
  }

  async rejectCall(message) {
    if (message && message.call && message.call.id) this.videoService.cancelCall(message.call.id);
    await this.audioService.stopRingTone(false);
  }

  async reactionPopover(event) {
    this.msgSelected = true;
    this.isModalMessageOpen = true;
    let popover = await this.popoverCtrl.create({
      component: ReactionIconsComponent,
      event: event,
      showBackdrop: false,
      // dismissOnSelect: true,
      size: 'auto',
      dismissOnSelect: true,
      // size: 'auto',
      side: this.position === 'right' ? 'left' : 'right',
      componentProps: {
        message: this.message,
        chat: this.chat,
        origin: this.origin
      },
      cssClass: 'no-background'
    });
    await popover.present();
    await popover.onWillDismiss();
    this.msgSelected = false;
    this.isModalMessageOpen = false;
    this.mouseOut(this.message);
  }

  async openReactionsInfoModal(message) {
    if(this.isSaved) return;
    this.modal = await this.modalCtrl.create({
      component: ReactionsInfoModal,
      initialBreakpoint: 0.3,
      breakpoints: [0, 0.3, 0.5, 0.75, 1],
      id: 'reactions-info-modal',
      componentProps: {
        message: message,
        currentUserId: this.currentUserId,
        chat: this.chat,
        origin: this.origin
      },
      swipeToClose: true
    });
    await this.modal.present();
  }

  closeSlidingFunc() {
    this.closeSliding.emit();
  }
}
