// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `workday-register-component {
  z-index: 1000;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: move;
}

ion-app {
  transition: margin 100ms;
}
ion-app.banner {
  margin-top: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;AACI;EACI,gBAAA;AACR","sourcesContent":["workday-register-component{\n    z-index: 1000;\n    position: absolute;\n    right: 10px;\n    top: 10px;\n    cursor: move;\n}\n\nion-app {\n    transition: margin 100ms;\n\n    &.banner {\n        margin-top: 90px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
