// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  justify-content: space-between;
}

.audio-controls {
  display: flex;
  align-items: center;
  width: 75%;
}

.play-button {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.progress-bar {
  -webkit-appearance: none;
          appearance: none;
  height: 2px;
  background-color: #ccc;
  padding: 0;
  cursor: pointer;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
}

.time {
  color: black;
  font-size: 10px;
  margin-left: 10px;
}

.duration {
  display: flex;
  color: white;
  font-size: 10px;
  margin-right: 4px;
}

.speed-button {
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/audio-thumb/audio-thumb.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,qCAAA;EACA,kBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;AACF;;AAEA;EACE,6BAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,wBAAA;UAAA,gBAAA;EACA,WAAA;EACA,sBAAA;EACA,UAAA;EACA,eAAA;AACF;;AAEA;EACE,wBAAA;UAAA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,oCAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AACF","sourcesContent":[".audio-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 10px;\n  width: 250px;\n  background-color: rgba(0, 0, 0, .05);\n  border-radius: 8px;\n  justify-content: space-between; \n}\n\n.audio-controls {\n  display: flex;\n  align-items: center;\n  width: 75%;\n}\n\n.play-button {\n  background-color: transparent;\n  border: none;\n  color: black;\n  font-size: 18px;\n  margin-right: 10px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.progress-bar {\n  appearance: none;\n  height: 2px;\n  background-color: #ccc;\n  padding: 0;\n  cursor: pointer;\n}\n\n.progress-bar::-webkit-slider-thumb {\n  appearance: none;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background-color: black;\n}\n\n.time {\n  color: black;\n  font-size: 10px;\n  margin-left: 10px;\n}\n\n.duration {\n  display: flex;\n  color: white;\n  font-size: 10px;\n  margin-right: 4px;\n}\n\n.speed-button {\n  background-color: rgba(0, 0, 0, 0.1);\n  color: black;\n  border-radius: 15px;\n  padding: 5px 10px;\n  font-size: 10px;\n  cursor: pointer;\n  text-align: center;\n  align-items: center;\n  display: flex;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
