import { Injectable } from "@angular/core";
import { CameraPreview, CameraPreviewOptions } from "@capacitor-community/camera-preview";
import { ModalController, NavController } from "@ionic/angular";


@Injectable({
    providedIn: 'root'
})
export class VideoRecordService {

    recording: boolean = false;
    cameraOptions: CameraPreviewOptions;
    recorderUrl;
    origin;
    chat;

    constructor(
        public modalCtrl: ModalController,
        public navCtrl: NavController,
    ) {}

    iniCameraPreview() {
        this.recording = false;
        this.cameraOptions = {
          position: 'rear',
          parent: "cameraPreview",
        };
        CameraPreview.start(this.cameraOptions);
    }

    async iniVideo() {
        this.recording = true;
        try {
          await CameraPreview.startRecordVideo(this.cameraOptions);
        } catch(e) {
          console.error(e);
        }
    }

    async stopVideo() {
        this.recording = false;
        try {
          let res = await CameraPreview.stopRecordVideo();
          console.log(res);
        } catch(e) {
          console.error(e);
        }
    }
    
    flipCamera() {
      try {
          CameraPreview.flip();
      } catch(e) {
          console.error(e);
      }
    }
    
    async closeCamera() {
      try {
          await CameraPreview.stop();
          await this.navCtrl.back();
      } catch(e) {
          console.error(e);
      }
    }
}