import { PluginListenerHandle, registerPlugin } from '@capacitor/core';

export interface RecordPlugin {
  setCamera(): Promise<any>;
  compressVideo(options: {input: string, output: string}): Promise<any>;
}

const RecordPlugin = registerPlugin<RecordPlugin>('RecordPlugin');

export default RecordPlugin;
