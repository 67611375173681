import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TextImgComponent} from './text-img/text-img.component';
import { PinComponent } from './pin/pin.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImageLoaderComponent } from './image-loader/image-loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSplitPaneListComponent } from './base-split-pane-list/base-split-pane-list.component';
import { BaseSplitPaneLandingComponent } from './base-split-pane-landing/base-split-pane-landing.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { HeaderAlertComponent } from './header-alert/header-alert.component';
import { AddUnavailableRulesComponent } from './add-unavailable-rules/add-unavailable-rules.component';
import { DescriptionComponent } from './description/description.component';
import { PrivacityComponent } from './privacity/privacity.component';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ReceiptStatusComponent } from './receipt-status/receipt-status.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ImageThumbComponent } from './image-thumb/image-thumb.component';
import { UserConnectionStatusComponent } from './user-connection-status/user-connection-status.component';
import { InfoMessageComponent } from './info-message/info-message.component';
import { ContactSelectorComponent } from './contact-selector/contact-selector.component';
import { MessageInfoComponent } from './message-info/message-info.component';
import { TextLoaderComponent } from './text-loader/text-loader.component';
import { NavBackButtonComponent} from './nav-back-button/nav-back-button.component';
import { NotesComponent } from './notes/notes.component';
import { ChatComponent } from './chat/chat.component';
import { CustomHTMLComponent } from './custom-html/custom-html.component';
import { ChartCanvasComponent } from './chart-canvas/chart-canvas.component';
import { MenuButtonComponent} from './menu-button/menu-button.component';
import { InternetWarningComponent } from './internet-warning/internet-warning.component';
import { AwardsComponent } from './awards/awards.component';
import { AwardsInfoComponent } from './awards-info/awards-info.component';
import { VideoComponent } from './video/video.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MenuContentComponent } from './menu-content/menu-content.component';
import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { JoditEditorComponent } from './jodit-editor/jodit-editor.component';
import { EditUserProfileComponent } from './edit-user-profile/edit-user-profile.component';
import { AppointmentsTableComponent } from './appointments-table/appointments-table.component';
import { SearchPatientModalComponent } from './search-patient-modal/search-patient-modal.component';
import { PipesModule } from '../pipes/pipes.module';
import { TooltipComponent } from './tooltip/tooltip.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { UserItemComponent } from './user-item/user-item.component';
import { ReactionsInfoModal } from './reactions-info-modal/reactions-info-modal.component';
// import { WorkdayRegisterComponent } from './workday-register/workday-register.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { VideoThumbComponent } from './video-thumb/video-thumb.component';
import { PdfThumbComponent } from './pdf-thumb/pdf-thumb.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileThumbComponent } from './file-thumb/file-thumb.component';
import { PdfViewerModalComponent } from './pdf-viewer-modal/pdf-viewer-modal.component';
import { AudioThumbComponent } from './audio-thumb/audio-thumb.component';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { ReactionsModule } from './reactions.module';

@NgModule({
  declarations: [
      TextImgComponent,
      PinComponent,
      ImageLoaderComponent,
      MenuComponent,
      MenuContentComponent,
      HeaderAlertComponent,
      AddUnavailableRulesComponent,
      DescriptionComponent,
      PrivacityComponent,
      BaseSplitPaneListComponent,
      BaseSplitPaneLandingComponent,
      ChatBubbleComponent,
      ReceiptStatusComponent,
      GalleryComponent,
      ImageThumbComponent,
      VideoThumbComponent,
      PdfThumbComponent,
      FileThumbComponent,
      AudioThumbComponent,
      PdfViewerModalComponent,
      PreviewModalComponent,
      UserConnectionStatusComponent,
      InfoMessageComponent,
      ContactSelectorComponent,
      MessageInfoComponent,
      TextLoaderComponent,
      NavBackButtonComponent,
      NotesComponent,
      ChatComponent,
      CustomHTMLComponent,
      ChartCanvasComponent,
      MenuButtonComponent,
      InternetWarningComponent,
      AwardsComponent,
      AwardsInfoComponent,
      VideoComponent,
      BarcodeScannerComponent,
      JoditEditorComponent,
      EditUserProfileComponent,
      AppointmentsTableComponent,
      SearchPatientModalComponent,
      TooltipComponent,
      UserItemComponent,
      ReactionsInfoModal,
      ProgressBarComponent,
      PhoneInputComponent,
      UserItemComponent,
      ReactionsInfoModal,
      ProgressBarComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    PipesModule,
    MatTooltipModule,
    PickerModule,
    ReactionsModule,
    PdfViewerModule,
    ReactionsModule
  ],
  exports: [
    TextImgComponent,
    PinComponent,
    ImageLoaderComponent,
    MenuComponent,
    MenuContentComponent,
    HeaderAlertComponent,
    ChatBubbleComponent,
    ReceiptStatusComponent,
    ImageThumbComponent,
    VideoThumbComponent,
    PdfThumbComponent,
    FileThumbComponent,
    PdfViewerModalComponent,
    AudioThumbComponent,
    PreviewModalComponent,
    UserConnectionStatusComponent,
    InfoMessageComponent,
    TextLoaderComponent,
    NavBackButtonComponent,
    NotesComponent,
    ChatComponent,
    CustomHTMLComponent,
    ChartCanvasComponent,
    MenuButtonComponent,
    VideoComponent,
    BarcodeScannerComponent,
    JoditEditorComponent,
    AppointmentsTableComponent,
    SearchPatientModalComponent,
    TooltipComponent,
    UserItemComponent,
    ReactionsInfoModal,
    ProgressBarComponent,
    PhoneInputComponent,
    ProgressBarComponent
  ]
})
export class SharedModule { }
