import { Component, OnInit, ViewChild, HostListener, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Platform, ModalController, IonTextarea } from '@ionic/angular';
import { FileService } from 'src/services/file.service';
import { TranslateService } from '@ngx-translate/core';

import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';
import { ChatService } from 'src/services/chat.service';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, AfterViewInit {

  isCapacitor: boolean;

  public slideOpts: any = {
    zoom: true,

  };

  @ViewChild('slides', { static: false }) slides;
  @Input() chatId: any;
  @Input() message: any;

  @ViewChild('textArea', { static: false }) textareaRef: IonTextarea;
  @ViewChild('slides', { static: false }) previewContainerRef: ElementRef;

  @HostListener('window:resize')
  onResize() {
    if (this.platform.is('ios')) {
      setTimeout(() => {
        if (this.slides) {
          this.slides.update();
        }
      }, 100);
    }
  }

  public image;
  maxHeight: any;

  constructor(public platform: Platform,
    public modalCtrl: ModalController,
    public tr: TranslateService,
    public fileService: FileService,
    public chatService: ChatService,) {
  }

  async dismiss() {
    await this.modalCtrl.dismiss(null, null, "imageModal");
  }

  ngOnInit() {
    if (!this.platform.is('capacitor')) {
      this.isCapacitor = false;
    } else {
      this.isCapacitor = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.maxHeight = this.previewContainerRef.nativeElement.offsetHeight;
    }, 250);
  }

  async ionViewWillEnter() {
    this.image = this.fileService.loadedImages[this.chatId][this.message.id];
    const event = await this.triggerInputEvent();
    this.calculateHeights(event);
    // console.log("THIS IMAGE", this.image);
    // let keys = Object.keys(this.images);
    // let i = 1;
    // console.log("msgID", this.messageId);
    // console.log("KEYS", keys);
    // keys.forEach(key => {
    //   if(key === this.messageId)
    //     this.slideOpts.initialSlide = i;
    //   i++;
    // });
    // if(this.slides)
    // this.slides.update();
  }

  async triggerInputEvent(): Promise<Event> {
    const textAreaElement = await this.textareaRef.getInputElement();

    return new Promise<Event>((resolve) => {
      const handler = (event: Event) => {
        textAreaElement.removeEventListener('input', handler);
        resolve(event);
      };

      textAreaElement.addEventListener('input', handler);
      textAreaElement.dispatchEvent(new Event('input'));
    });
  }

  async download() {
    if(!this.platform.is('capacitor')) await Browser.open({ url: this.image.url });
    else await this.fileService.showDownloadWarning(this.image.url, this.image.name, this.image.type, true )
  }

  async share(){
    let translations = [];
    await this.tr.get(['documents']).forEach(tr => translations = tr);
    let shareRet = await Share.share({
      title: this.image.name,
      text: this.image.name,
      url:  this.image.url,
      dialogTitle: translations['documents'].share
    });
  }

  calculateHeights (e: Event) {
    this.chatService?.chatToolbarHeight(e);
    this.adjustPreviewHeight();
  }

  async adjustPreviewHeight() {
    const textarea = await this.textareaRef.getInputElement()
    const textareaHeightString = textarea.style.height;
    const textareaHeight = textareaHeightString ? parseInt(textareaHeightString.replace('px', ''), 10) : textarea.scrollHeight;
    const maxHeight = this.maxHeight;
    const adjustedHeight = maxHeight - textareaHeight + 20;

    if (this.previewContainerRef && this.previewContainerRef.nativeElement && adjustedHeight > 0) {
        this.previewContainerRef.nativeElement.style.height = `${adjustedHeight}px`;
    }
  }

}
