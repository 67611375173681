import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Items, PopoverComponent } from './popover.component';

@Injectable({
    providedIn: 'root'
})
export class PopoverService {

    constructor(
        private popoverCtrl: PopoverController
    ) {
    }

    async presentPopover(ev: Event, items: Items[], isHold: boolean) {
      const popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        mode: 'md',
        componentProps: { items, isHold },
        event: ev,
        cssClass: 'no-background'
      //   dismissOnSelect: true
      });
      await popover.present();
      return await popover.onWillDismiss();
    }
}
