// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reaction-icons {
  margin: auto;
  max-width: 300px;
  justify-content: space-between;
  text-align: center;
  font-size: 20px;
  border-radius: 25px;
  background: white;
  border: 1px solid lightgrey;
}
.reaction-icons ion-col {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/reaction-icons/reaction-icons.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EAQA,mBAAA;EACA,iBAAA;EACA,2BAAA;AANJ;AAFI;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAIR","sourcesContent":[".reaction-icons {\n    margin: auto;\n    max-width: 300px;\n    justify-content: space-between;\n    text-align: center;\n    font-size: 20px;\n\n    ion-col {\n        cursor: pointer;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n    border-radius: 25px;\n    background: white;\n    border: 1px solid lightgrey;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
