import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TimeService } from './time.service';
import { AuthService } from './auth.service';

import { Browser } from '@capacitor/browser';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    public alert: HTMLIonAlertElement;
    constructor(public alertCtrl: AlertController,
        public platform: Platform,
        public authService: AuthService,
        public timeService: TimeService,
        public tr: TranslateService) { }

    async presentAlert(message, buttons = [], awaitDismiss = false): Promise<void> {
        let translations = {};
        await this.tr.get(['general.close']).forEach(x => translations = x);
        this.alert = await this.alertCtrl.create({
            message,
            buttons: [
                ...[
                    {
                        text: translations['general.close'],
                        role: 'cancel',
                    }
                ],
                ...buttons
            ]
        });

        await this.alert.present();
        if (awaitDismiss) await this.alert.onDidDismiss();
        return;
    }


    async showError(errorKey = 'errors.general', awaitDismiss = false, params = {}): Promise<void> {
        let translations = {};
        await this.tr.get(['general.error', errorKey, 'general.ok'], params).forEach(x => translations = x);
        this.alert = await this.alertCtrl.create({
            header: translations['general.error'],
            message: translations[errorKey],
            buttons: [
                {
                    text: translations['general.ok'],
                    role: 'cancel',
                }
            ]
        });

        await this.alert.present();
        if (awaitDismiss) await this.alert.onDidDismiss();
        return;
    }

    async showFileAlert(fileName, voiceMsg = false) {
        let translations = [];
        await this.tr.get(['loader.msg_title', 'general', 'documents']).forEach(x => translations = x);
        this.alert = await this.alertCtrl.create({
            header: voiceMsg ? translations['documents'].voiceTitle : translations['documents'].sendTitle,
            message: voiceMsg ? translations['documents'].send_voice : (translations['documents'].send_msg + `"${fileName}"?`),
            buttons: [
                {
                    text: translations['general'].yes,
                    handler: async () => {
                        this.alert.dismiss(true);
                    }
                },
                {
                    text: translations['general'].cancel,
                    role: 'cancel',
                    handler: async () => {
                        this.alert.dismiss(false);
                    }
                }
            ]
        });
        return this.alert;
    }


    async showLogoutAlert() {
        if (this.alert) {
            await this.alert.dismiss();
        }
        let translations = [];
        await this.tr.get(['general', 'settings']).forEach(tr => translations = tr);
        this.alert = await this.alertCtrl.create({
            header: translations['settings'].logout.warnTitle,
            message: translations['settings'].logout.warn,
            backdropDismiss: true,
            buttons: [
                {
                    text: translations['general'].yes,
                    handler: async () => {
                        this.authService.logout();
                    }
                },
                {
                    text: translations['general'].no,
                    role: 'cancel',
                    handler: async () => {
                        this.alert.dismiss(false);
                    }
                }
            ]
        });
        return this.alert.present();
    }

    async showActionAlert() {
        if (this.alert) {
            await this.alert.dismiss();
        }
        let translations = [];
        await this.tr.get(['general', 'settings']).forEach(tr => translations = tr);
        this.alert = await this.alertCtrl.create({
            header: translations['settings'].logout.warnTitle,
            message: translations['settings'].logout.warn,
            backdropDismiss: true,
            buttons: [
                {
                    text: translations['general'].yes,
                    handler: async () => {
                        this.authService.logout();
                    }
                },
                {
                    text: translations['general'].no,
                    role: 'cancel',
                    handler: async () => {
                        this.alert.dismiss(false);
                    }
                }
            ]
        });
        return this.alert.present();
    }

    async showMedxatStopAlert(message ?) {
        if (this.alert) {
            await this.alert.dismiss();
        }
        let translations = [];
        await this.tr.get(['general']).forEach(tr => translations = tr);
        if (!message) {
            message = translations['general'].medxatAlert.message;
        }
        this.alert = await this.alertCtrl.create({
            header: translations['general'].medxatAlert.title,
            message,
            backdropDismiss: false,
        });
        return this.alert;
    }

    async showMedxatVersionAlert(maxDate) {
        if (this.alert) {
            await this.alert.dismiss();
        }
        let translations = [];
        await this.tr.get(['general.appUpdate']).forEach(tr => translations = tr);

    let buttons: any = [
        {
            text: translations['general.appUpdate'].update,
            handler: async () => {
                //TODO POSAR LINKS STORES
                if (this.platform.is('android')) {
                    // this._market.open('com.mhce.app');
                    await Browser.open({ url: 'https://play.google.com/store/apps/details?id=com.mhce.app&hl=ca' });
                } else if (this.platform.is('ios')) {
                    // this._market.open('id1283026127');
                    await Browser.open({ url: 'https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1283026127&mt=8' });
                }
            }}];
        if (maxDate > this.timeService.getDateToday()) {
            buttons.push({
                text: translations['general.appUpdate'].later,
                handler: () => { }
            });
        }
        this.alert = await this.alertCtrl.create({
            header: translations['general.appUpdate'].title,
            message: translations['general.appUpdate'].message + ' ' + maxDate,
            backdropDismiss: maxDate > this.timeService.getDateToday() ? true : false,
            buttons
        });
        return this.alert;
    }

    /**
     * Funció que presenta un alert de Ionic de confirmació
     * 
     * @params AlertOptions options Opcions de l'alert
     */
    async confirmIonicAlert(options?:AlertOptions){ 
        options = {
        cssClass: options?.cssClass ?? 'custom-alert',
        header: options?.header ?? this.tr.instant('general.confirm'),
        subHeader: options?.subHeader ?? "",
        message: options?.message ?? this.tr.instant('general.alerts.confirm_message'),
        buttons: options?.buttons ?? [{
            text: this.tr.instant('general.yes'),
            role: 'yes'
            },
            {
            text: this.tr.instant('general.no'),
            role: 'no'
            }
        ]
        };
        
        const alert = await this.alertCtrl.create(options);
        if(await this.alertCtrl.getTop() === undefined) await alert.present();

        const { role } = await alert.onDidDismiss();
        return role === 'yes';
    }
}
