import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileService } from 'src/services/file.service';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'pdf-thumb',
  templateUrl: './pdf-thumb.component.html',
  styleUrls: ['./pdf-thumb.component.scss'],
})
export class PdfThumbComponent {

  @Input() originalWidth: number;
  @Input() originalHeight: number;
  @Input() key?: string;
  @Input() orgKey?: string;
  @Input() chatId: string;
  @Input() position: string;
  @Input() origin: string;
  loading: boolean;

  pdfSrc: string;
  namePdf: string;
  numPages: number;
  pageLabel: string;
  size: string;
  loaded: any;
  width: number;
  data: any;
  imageError = false;

  @Input()
  set pdfInputData(data: any) {
    this.loading = true;
    this.data = data;
    this.namePdf = data.pdf.name;
    if (data.pdf.info) {
      this.pdfSrc = data.pdf.info.url;
      this.numPages = data.pdf.info.numPages;
      if (this.numPages > 1) {
        this.pageLabel = 'chat.pages';
      } else {
        this.pageLabel = 'chat.page'
      }
      this.size = data.pdf.info.size;
      this.imageError = false;
      this.loading = false;
    } else {
      this.imageError = true;
      this.loading = false;
      /* try {
        this.fileService.generatePdfThumbnail(data.pdf.url).then(response =>{
          this.pdfSrc = response.url;
          this.numPages = response.numPages;
          if (this.numPages > 1) {
            this.pageLabel = 'chat.pages';
          } else {
            this.pageLabel = 'chat.page'
          }
          this.size = response.size;
          this.chatService.updateQueuePdfThumb(data.orgKey, data.chat.id, data.message.id, data.origin, response)
        });
        this.imageError = false;
        this.loading = false;
      } catch (e) {
        console.log("@PDF-THUMB: error generant thumb pdf: ", e)
        this.imageError = true;
        this.loading = false;
      } */
    }
  }

  constructor(public platform: Platform, public fileService: FileService, public chatService: ChatService) { }

  openPdfModal() {
    this.chatService.showPdfModal(this.data.message, this.data.chat.id, this.data.pdf.url);
  }
  
}
