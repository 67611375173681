import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { CommunicationService } from './communication.service';
import { TimeService } from './time.service';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

const MOCK = false;
const sortTypes = {
  RECENT_FIRST: 'recent_first',
  LAST_FIRST: 'last_first',
  NAME_ASCENDENT: 'name_ascendent',
  NAME_DESCENDENT: 'name_descendent',
  FLOOR_ASCENDENT: 'floor_ascendent',
  FLOOR_DESCENDENT: 'floor_descendent',
  ORIGINAL: 'original'
}

@Injectable({
  providedIn: 'root'
})
export class GuttmannService {

  selectedPatient;
  selectedSession;

  patientSelection = false;
  selectedPatients = [];
  selectedPatientsObj = {};
  oppenAccordion;

  patientAppointments;
  selectedVideoCall;
  userId;
  showMyAppointments;
  appointmentsSortType;
  patientSortTypes;
  allPatients = [];
  settings;

  patientsPictures = {};
  loadingPictures = [];
  requestingPictures = false;
  checkPendingEF$ = new Subject<void>();
  picturesInterval$ = new Subscription;
  picturesIntervalStarted = false;

  constructor(
    public cs: CommunicationService,
    public http: HttpClient,
    public toastCtrl: ToastController,
    public timeService: TimeService,
    public ts: TranslateService,
    public as: AuthService
  ) {
    this.showMyAppointments = true;
    this.appointmentsSortType = sortTypes.RECENT_FIRST;
    this.patientSortTypes = sortTypes.ORIGINAL;
  }

  /**
   * Endpoint que retorna els settings
   */
  getSettings() {
    if (MOCK) return this.http.get('assets/mocks/IG/settings-mock.json').toPromise();
    return this.cs.request('ig', '/ig/settings', 'get');
  }

  /**
   * Endpoint per autenticar amb el token d'azure
   * @returns array
   */
  auth(token) {
    return this.cs.request('ig', '/ig/public/auth/login', 'get', {token});
  }

  /**
   * Endpoint per crear una cita online
   */
  createOnlineAppointment(params) {
    return this.cs.request('ig', '/ig/professional/online_appointments', 'post', params);
  }

  changeAppointmentState(params, id) {
    return this.cs.request('ig', `/ig/professional/online_appointments/${id}/state`, 'put', params);
  }

  changeAppointmentDate(params, id) {
    return this.cs.request('ig', `/ig/professional/online_appointments/${id}/date`, 'put', params);
  }

  changeAppointmentTitle(params, id) {
    return this.cs.request('ig', `/ig/professional/online_appointments/${id}/title`, 'put', params);
  }

  changeAppointmentUsers(params, id) {
    return this.cs.request('ig', `/ig/professional/online_appointments/${id}/modify_users`, 'patch', params);
  }

  getProfessionalAppointments(params) {
    if (MOCK || this.as?.testMode) return this.http.get('assets/mocks/validation/appointments.json').toPromise();
    return this.cs.request('ig', '/ig/videocalls/professional/appointments', 'post', params);
  }

  getPatientAppointments(params) {
    if (MOCK) {
      setTimeout(() => {
        return this.http.get('/assets/mocks/guttman-appointments-mock.json').toPromise();
      }, 2000);
    }
    return this.cs.request('ig', '/ig/videocalls/professional/patient-appointments', 'post', params);
  }

  getPatient(nhc) {
    if (MOCK) {
      return this.http.get('/assets/mocks/IG/patient-notify.json').toPromise();
    }
    return this.cs.request('ig', `/ig/videocalls/professional/patient/${nhc}`, 'get');
  }

  getOnlineAppointmentJwtToken(params) {
    return this.cs.request('ig', `/ig/professional/online_appointments/${this.selectedVideoCall.id}/jwt`, 'put', { ...this.selectedVideoCall, ...params });
  }

  notifyOnlineAppointment(appointment, data) {
    return this.cs.request('ig', `/ig/professional/online_appointments/notify`, 'post', { ...appointment, notification_data: data });
  }

  getOnlineAppointmentWhiteboardLink() {
    return this.cs.request('ig', `/ig/professional/online_appointments/${this.selectedVideoCall.id}/link`, 'get');
  }

  getHelpDocumentation() {
    return this.cs.request('ig', `/ig/online_appointments/help`, 'get');
  }


  /**
   * Endpoint que recupera el llistat de pacients assignats a l'usuari logat
   * @returns array
   */
  searchPatient(name) {
    // this.cs.cancelPendingRequests$.next();
    if (MOCK) return this.http.get('assets/mocks/IG/patients-list-mock.json').toPromise();
    return this.cs.request('ig', '/ig/patients', 'get', name);
  }

  /**
   * Endpoint que recupera el llistat de pacients assignats a l'usuari logat
   * @returns array
   */
  getPatientsList() {
    if (MOCK) return this.http.get('assets/mocks/IG/patients-list-mock.json').toPromise();
    //TODO TREURE MOCK
    return this.cs.request('ig', '/ig/patients', 'get');
  }

  async getPatientMock() {


    let patient1 = await this.cs.request('ig', '/ig/patients/31147', 'get');
    let patient2 = await this.cs.request('ig', '/ig/patients/31927', 'get');
    let patient3 = await this.cs.request('ig', '/ig/patients/31852', 'get');
    let patient4 = await this.cs.request('ig', '/ig/patients/1061', 'get');
    let patient5 = await this.cs.request('ig', '/ig/patients/30024', 'get');

    return [patient1, patient2, patient3, patient4]
    // let patients = [patient1, patient2, patient3, patient4, patient5];
    // console.log("patients", patients)
    // return patients;

  }

  /**
   * Endpoint que recupera el detall d'un pacient
   * @param patientId Id del pacient que es vol recuperar el detall
   */
  getPatientDetail(patientId) {
    // this.cs.cancelPendingRequests$.next();
    if (MOCK) return this.http.get('assets/mocks/IG/patient-detail-mock.json').toPromise();
    return this.cs.request('ig', `/ig/patients/${patientId}`, 'get');
  }

  getPatientChecklist(patientId) {
    return this.cs.request('ig', `/ig/patients/${patientId}/checklist`, 'get');
  }

  /**
   * DEPRECATED
   * @param patientId 
   * @param params 
   * @param force 
   * @returns 
   */
  async getPatientEF(patientId, params, force = true) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-ef-mock.json').toPromise();
    let index = this.allPatients ? this.allPatients.findIndex(p => p && p.id === patientId) : -1;
    let day = params.day;
    let isToday = day === this.timeService.getDateToday();
    let result;
    if (!force && isToday && index !== -1 && this.allPatients[index] && this.allPatients[index].ef) {
      result = this.allPatients[index].ef;
    } else {
      result = await this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/reports`, 'get', params);
    }
    if (index != -1) {
      this.allPatients[index].ef = result;
    }
    return Promise.resolve(result);
  }

  /**
   * Recupera subrpogrames d'un pacient i data. incloent progress
   * @param patientId 
   * @param params 
   * @param force 
   * @returns 
   */
  async getPatientPrograms(patientId, params, force = true) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-programs-mock.json').toPromise();
    let index = this.allPatients ? this.allPatients.findIndex(p => p && p.id === patientId) : -1;
    let date = params.date;
    let isToday = date === this.timeService.getDateToday();
    let result;
    if (!force && isToday && index !== -1 && this.allPatients[index] && this.allPatients[index].programs) {
      result = this.allPatients[index].programs;
    } else {
      result = await this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/programs`, 'get', params);
    }
    if (index != -1) {
      this.allPatients[index].programs = result;
    }
    return Promise.resolve(result);
  }

  /**
   * Recuperem sessions d'un pacient, programa i data. incloent progress
   * @param patientId 
   * @param programId 
   * @param params 
   * @param force 
   * @returns 
   */
  async getPatientSession(patientId, programId, params, force = true) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-sessions-mock.json').toPromise();
    let index = this.allPatients ? this.allPatients.findIndex(p => p && p.id === patientId) : -1;
    let date = params.date;
    let isToday = date === this.timeService.getDateToday();
    let result;
    if (!force && isToday && index !== -1 && this.allPatients[index] && this.allPatients[index].sessions) {
      result = this.allPatients[index].sessions;
    } else {
      result = await this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/programs/${programId}`, 'get', params);
    }
    if (index != -1) {
      this.allPatients[index].sessions = result;
    }
    return Promise.resolve(result);
  }

  /**
   * Recuperem grups i activitats d'un pacient, programa, sessió i data
   * @param patientId 
   * @param programId 
   * @param sessionType 
   * @param params 
   * @param force 
   * @returns 
   */
  async getPatientGroupActivities(patientId, programId, sessionType, params, force = true) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-activities-mock.json').toPromise();
    let index = this.allPatients ? this.allPatients.findIndex(p => p && p.id === patientId) : -1;
    let date = params.date;
    let isToday = date === this.timeService.getDateToday();
    let result;
    if (!force && isToday && index !== -1 && this.allPatients[index] && this.allPatients[index].activities) {
      result = this.allPatients[index].activities;
    } else {
      result = await this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/programs/${programId}/${sessionType}`, 'get', params);
    }
    if (index != -1) {
      this.allPatients[index].activities = result;
    }
    return Promise.resolve(result);
  }


  /**
   * Recuperem el progress d'una sessió llistat d'activitats
   * @param patientId 
   * @param programId 
   * @param sessionType 
   * @param params 
   * @returns 
   */
  async getPatientSessionProgress(patientId, programId, sessionType, params) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-activities-mock.json').toPromise();
    return await this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/progress/${programId}/${sessionType}`, 'get', params);
  }

  /**
   * Recuperem informació d'un conjunt d'activitats amb filtres
   * @param params 
   * @returns 
   */
  async getFilteredActivities(params) {
    if (MOCK) return this.http.get('assets/mocks/IG/patient-filtered-activities-mock.json').toPromise();
    return await this.cs.request('ig', `/ig/ef/v2/activities`, 'post', params);

  }

  /**
   * Actualitzem dades d'una sessió / activitats
   * @param params 
   * @returns 
   */
  async updateSession(params) {
    return await this.cs.request('ig', `/ig/ef/v2/activities`, 'put', params);
  }

  /**
   * Eliminem activitat d'una sessió. duration: 0
   * @param params 
   * @returns 
   */
  async deleteActivitySession(params) {
    return await this.cs.request('ig', `/ig/ef/v2/activities`, 'delete', params);
  }

  /**
   * Change the absence state of a session type. For the current date.
   * @param patientId 
   * @param programId 
   * @param sessionType 
   * @param params 
   * @returns 
   */
  notifyAbsence(patientId, programId, sessionType, params) {
    return this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/programs/${programId}/${sessionType}/absence`, 'post', params);
  }

  /**
   * Return the progress report for an interval of dates of a concrete patient.
   * @param patientId 
   */
  getPatientProgress(patientId, params) {
    return this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/progress`, 'get', params);
  }

  /**
   * Return the progress report for an interval of dates of a concrete session
   * @param patientId 
   * @param programId 
   * @param sessionType 
   * @param params 
   * @returns 
   */
  getPatientProgressSession(patientId, programId, sessionType, params) {
    return this.cs.request('ig', `/ig/ef/v2/patients/${patientId}/progress/${programId}/${sessionType}`, 'get', params);
  }

  savePatientEF(patientId, reportId, params) {
    return this.cs.request('ig', `/ig/ef/patients/${patientId}/reports/${reportId}/reported_activities`, 'put', params)
  }
  getEFModules() {
    if (MOCK) return this.http.get('assets/mocks/IG/modules-ef-mock.json').toPromise();
  }

  getEFTimelineStats(patientId, params) {
    // return this.http.get('assets/mocks/IG/timeline-stats-ef-mock.json').toPromise();
    return this.cs.request('ig', `/ig/ef/patients/${patientId}/timeline`, 'get', params)
  }
  getEFTableStats(patientId, params) {
    // if (MOCK) return this.http.get('assets/mocks/IG/table-stats-ef-mock.json').toPromise();
    return this.cs.request('ig', `/ig/ef/patients/${patientId}/stats`, 'get', params)
  }

  getEFStats(date) {
    return this.cs.request('ig', `/admin/ig/ef/stats?month=${date}`, 'get');
  }

  getVisitsStats(date) {
    return this.cs.request('ig', `/ig/admin/online_appointments?date=${date}`, 'get');
  }

  /**
   * Start interval to get pictures from server every 2s
   * if requestingPictures wait until is false
   */
  startPicturesInterval() {
    this.picturesIntervalStarted = true;
    this.picturesInterval$ = interval(1000).subscribe(() => {
      if (!this.requestingPictures && this.loadingPictures && this.loadingPictures.length > 0) {
        this.receivePatientPictures();
      }
    })
  }

  /**
    * End interval to get pictures from server every 2s
    */
  endPicturesInterval() {
    if (this.picturesInterval$) this.picturesInterval$.unsubscribe();
    this.picturesIntervalStarted = false;
  }

  /**
  * Get patient picture
  * if loaded return from patientsPictures
  * if !loaded add in request array loadingPictures
  * if !picturesIntervalStarted request start interval
  * if force request from server 
  */
  async getPatientPicture(patientId, force = false) {
    let picture;
    if (MOCK) return;
    if (this.patientsPictures && this.patientsPictures[patientId]) {
      picture = this.patientsPictures[patientId];
      return picture;
    } else {
      if (force) {
        picture = await this.cs.request('ig', `/ig/patients/${patientId}/picture`, 'get');
        this.patientsPictures[patientId] = picture;
        return picture;
      } else {
        let index = this.loadingPictures.indexOf(patientId)
        if (index != -1) return null;
        this.loadingPictures.push(patientId);
        if (this.loadingPictures.length != 0 && !this.picturesIntervalStarted) this.startPicturesInterval();
        return null;
      }
    }
  }




  /**
   * Requests patient pictures in batchs of 3
   */
  async receivePatientPictures() {
    let picture;
    if (MOCK) return;
    this.requestingPictures = true;
    let tmpPictures = this.loadingPictures.length < 3 ? this.loadingPictures.length : 3;
    for (let i = 0; i < tmpPictures; i++) {
      let patientId = this.loadingPictures[i];
      try {
        picture = await this.cs.request('ig', `/ig/patients/${patientId}/picture`, 'get');
        this.patientsPictures[patientId] = picture;
      } catch (e) {

      }
    }
    this.loadingPictures.splice(0, tmpPictures);
    this.requestingPictures = false;
    if (this.loadingPictures.length === 0) this.endPicturesInterval();
  }

  selectPatient(patient) {
    let pt = JSON.parse(JSON.stringify(patient));
    let index = this.selectedPatients.findIndex(a => a.id === pt.id);
    console.log("PATIENT TO SELECT", patient);

    if (index === -1) {
      if (this.selectedPatients.length > 0) {
        let patient_programs = patient?.programs?.map(program => program.program_code);
        let commonElements = false;
        for (let selection of this.selectedPatients) {
          let selection_programs = selection?.programs?.map(program => program.program_code);
          commonElements = selection_programs.some((element) => patient_programs.includes(element))
          if (commonElements) break;
        }
        if (!commonElements) {
          this.showToastNoCommonPrograms(patient_programs);
        }
      }

      this.selectedPatients.push(pt);
      this.selectedPatientsObj[pt.id] = true;
    } else {
      this.selectedPatients.splice(index, 1);
      this.selectedPatientsObj[pt.id] = false;
    }
  }

  async showToastNoCommonPrograms(programs) {
    let translations = [];
    await this.ts.get(['general']).forEach(tr => translations = tr);

    let done = await this.toastCtrl.create({
      message: this.ts.instant('tm.patients_module.ef.no_common_programs', { programs: JSON.stringify(programs) }),
      duration: 3000,
      position: 'top',
    });

    return done.present();
  }
}