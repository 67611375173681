import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PinPageModule } from './pin/pin.module';
import { AuthInterceptor } from '../services/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { IonicGestureConfig } from './shared/ionic-gesture/ionic-gesture-config';
import { NavProxyService } from '../services/nav-proxy-service.service';
import { CalendarModule } from 'ion7-calendar';
import { DatePipe, registerLocaleData } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import 'hammerjs';
import { OAuthModule } from 'angular-oauth2-oidc';
import myLocaleEs from '@angular/common/locales/es';
import { PdfViewerModule } from 'ng2-pdf-viewer';

registerLocaleData(myLocaleEs);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        PinPageModule,
        SharedModule,
        CalendarModule,
        ReactiveFormsModule,
        FormlyModule.forRoot(),
        FormlyBootstrapModule,
        HammerModule,
        FullCalendarModule,
        OAuthModule.forRoot(),
        PdfViewerModule
    ],
    providers: [
        NavProxyService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: IonicGestureConfig
        },
        DatePipe
    ],
    exports: [
        
    ],
    bootstrap: [AppComponent]

})
export class AppModule { }

