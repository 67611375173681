import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IonTextarea, ModalController, Platform } from '@ionic/angular';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent  implements OnInit, AfterViewInit {

  @Input() file: any;
  @Input() chatKey: any;
  @Input() voiceMsg: boolean;

  @ViewChild('textArea', { static: false }) textareaRef: IonTextarea;
  @ViewChild('previewContainer', { static: false }) previewContainerRef: ElementRef;

  previewUrl: SafeResourceUrl;  
  messageText: string = '';   
  isCapacitor: boolean;  
  maxHeight: any;

  constructor(
    private modalCtrl: ModalController, 
    private sanitizer: DomSanitizer,
    public chatService: ChatService,
    public platform: Platform,
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.maxHeight = this.previewContainerRef.nativeElement.offsetHeight;
    }, 250);
  }

  ngOnInit() {
    if (!this.platform.is('capacitor')) {
      this.isCapacitor = false;
    } else {
      this.isCapacitor = true;
    }
    if (this.file) {
      const reader = new FileReader();
      if (this.file.type.startsWith('video/')) {
        this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.file));
      } else {
        reader.onload = (e: any) => {
          if (this.file.type.startsWith('application/pdf')) {
            const pdfBase64 = e.target.result;
            if (!this.isCapacitor) this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfBase64);
            else this.previewUrl = pdfBase64;
          } else {
            this.previewUrl = e.target.result;
          }
        };
        reader.readAsDataURL(this.file);
      }
    }
    
  } 

  close() {
    this.modalCtrl.dismiss();
  }

  send() {
    this.modalCtrl.dismiss({ message: this.messageText, file: this.file });
  }

  onKeyPress(event, chat_id) {
    if (!this.platform.is('capacitor')) {
      if ((event.key === 'Enter') && !event.shiftKey) {
        event.preventDefault();
        this.send();
      }
    }
  }

  async adjustPreviewHeight() {
    const textarea = await this.textareaRef.getInputElement()
    const textareaHeightString = textarea.style.height;
    const textareaHeight = textareaHeightString ? parseInt(textareaHeightString.replace('px', ''), 10) : textarea.scrollHeight;
    const maxHeight = this.maxHeight;
    const adjustedHeight = maxHeight - textareaHeight + 20;

    if (this.previewContainerRef && adjustedHeight > 0) {
        this.previewContainerRef.nativeElement.style.height = `${adjustedHeight}px`;
    }
  }

  calculateHeights (e: Event) {
    this.chatService?.chatToolbarHeight(e);
    this.adjustPreviewHeight();
  }

}
