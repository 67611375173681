import { Component, OnInit, Input } from '@angular/core';
import { NavProxyService } from '../../../services/nav-proxy-service.service';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'nav-back-button',
  templateUrl: './nav-back-button.component.html',
  styleUrls: ['./nav-back-button.component.scss'],
})
export class NavBackButtonComponent implements OnInit {

  @Input() 
  public router: any = false;

  constructor(
    public NavProxy: NavProxyService,
    public location: Location,
    public platform: Platform
  ) { }

  ngOnInit() { }

  goBack() {
    if (this.router) {
      this.location.back();
      return;
    }
    this.NavProxy.pop();
  }
}
