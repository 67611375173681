import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ColorGenerator } from '../text-img/color-generator';
import { UserService } from 'src/services/user.service';
import { LoadingService } from 'src/services/loading.service';
import { TimeService } from 'src/services/time.service';
import { Platform, ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/services/communication.service';
import { takeUntil } from 'rxjs/operators';
import { ReceiptStatus, ReceiptStatusUtil } from '../receipt-status/receipt-status.component';
import { NavProxyService } from 'src/services/nav-proxy-service.service';
import { ViewDetailPage } from 'src/app/module-navigation/views/view-detail/view-detail.page';
import { ChatService } from 'src/services/chat.service';


@Component({
  selector: 'chat-component',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  public msgValue: any;
  public bloc: any;
  public messages: any[] = [];
  public loading: boolean = false;
  public loaded: boolean = false;
  public item: any;
  public module_id: any;
  public order = 'asc';

  public alert;
  public loader;
  public onDestroy$ = new Subject<void>();
  public forceLoadBloc$ = new Subject<boolean>();

  @ViewChild('chatList', { static: false }) chatList: any;

  @Input()
  set chatValue(messages: any[]) {
    if (messages) {
      this.messages = messages;
      this.messages.reverse();
      this.messages.forEach((msg) => {
        this.updateChatByConditions(msg);
      })
    }
  }

  @Input()
  set chatBloc(bloc: any) {
    this.bloc = bloc;
    if (bloc.event_load) {
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set detailItem(item) {
    if (item) {
      this.item = item;
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set moduleId(module_id) {
    if (module_id) {
      this.module_id = module_id;
      this.forceLoadBloc$.next(true);
    }
  }

  constructor(
    public navProxy: NavProxyService,
    public userService: UserService,
    public timeService: TimeService,
    public platform: Platform,
    public tr: TranslateService,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController,
    public commService: CommunicationService,
    public loadingService: LoadingService,
    public toastController: ToastController,
    public chatService: ChatService
  ) {
    this.forceLoadBloc$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (!this.loaded && this.item && this.bloc && this.bloc.event_load && this.module_id) {
          this._loadBloc(this.bloc.event_load);
        }
      });
  }

  ngOnInit() {

  }

  refresh() {
    if (this.bloc)
      this._loadBloc(this.bloc.event_load);
  }

  _updateMessage(action: any, actionData: any = [], msg: any) {
    if (action === 'delete' && msg) {
      let index = this.messages.indexOf(msg);
      this.messages.splice(index, 1);
      return true;
    } else if (action === 'insert') {
      actionData.forEach(result => {
        this.messages.push(result);
      });
      this.msgValue = '';
      return true;
    } else if (action === 'update') {
      actionData.forEach(result => {
        let res = result.tag.split('.');
        let field = res[0];
        let tag = res[1];
        let index = this.messages.indexOf(msg);
        this.messages[index][tag] = result.value;
      });
      return true;
    }
    return false;
  }

  async clickAction(action, msg?) {
    if (action.params && action.params.action === 'create' && !this.msgValue) {
      return;
    }
    await this.loadingService.presentLoading();
    try {
      let params = Object.assign({}, action.params);
      var keys = Object.keys(params);
      keys.forEach(key => {
        if (params[key].tag) {
          var tag = params[key].tag;
          params[key] = this.getValue(tag, msg);
        } else {
          params[key] = params[key];
        }
      });

      let op = action.op ? action.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : 'mirth';
      try {
        let result = await this.commService.getFunctionPromise(op, params, event_parent)
        if (event_parent === 'mirth' && result['data'] && result['data']['MessageCode'] === 200) {
          if (event_parent === 'mirth' && result['data']['action']) {
            var res_action = result['data']['action'];
            var actionData = result['data']['actionData'];
            var applied = this._updateMessage(res_action, actionData, msg);
            if (!applied) {
              await this.showErrorAlert('error');
            }
          }
        } else if (result['data']) {
          // result['data'].rcvd = new Date();
          var res_action = result['data']['action'];
          if (op === 'delete') {
            let index = this.messages.indexOf(msg);
            this.messages.splice(index, 1);
          } else if (op === 'create') {
            if (this.order === 'desc') {
              this.messages.unshift(result['data']);
            } else {
              this.messages.push(result['data']);
            }
            this.msgValue = '';
            this.scrollToBottom();
          } else if (op === 'update') {
            let index = this.messages.indexOf(msg);
            this.messages[index] = { ...this.messages[index], ...result['data'] };
            if (action.condition) {
              this.updateChatByConditions(result['data'], action, index);
            }
          }
        } else {
          await this.showErrorAlert(result['data']['ErrorMessage']);
        }
      } catch (e) {
        await this.showErrorAlert('Error: ' + e);
      }
      await this.loadingService.dismissLoading();

    } catch (e) {
      await this.loadingService.dismissLoading();
      await this.showErrorAlert('Error: ' + e);
    }
  }

  async confirmAction(confirmAction: any, action: any, message: any) {
    let translations = [];
    await this.tr.get(['general', 'census']).forEach(tr => translations = tr);

    if (this.alert) {
      this.alert.dismiss();
      this.alert = null;
    }
    let buttons = [];
    confirmAction.buttons.forEach(btn => {
      buttons.push({
        text: btn.title,
        role: btn.role,
        handler: () => {
          if (btn.role === 'confirm') {
            this.clickAction(action, message);
          }
        }
      });
    });
    this.alert = await this.alertCtrl.create({
      header: confirmAction.title,
      buttons
    });
    return this.alert.present();
  }

  //TODO: OUT OF THE COMPONENT!
  async clickBlock(bloc: any) {
    if (bloc.event) {
      let op = bloc.event;
      let event_parent = bloc.event_parent;
      let param = this.getValue(bloc.params);

      await this.loadingService.presentLoading();
      try {
        let resp = await this.commService.getFunctionPromise(op, param, event_parent)

        if (resp.data && resp.result === 200) {
          await this.navProxy.pushDetail(ViewDetailPage, { item: this.item, vistaDetall: resp.data.vistaDetall, module_id: this.module_id })
        } else {
          await this.showToast(resp.result);
        }
      } catch (errror) {
        await this.showToast()
      }
      await this.loadingService.dismissLoading();
    }
  }

  getValue(val: any, message?: any) {
    if (!val) {
      return;
    }

    let params = [];
    if (val && val.tag) {
      params = val.tag.split('.');
    } else {
      params = val.split('.');
    }

    let field = params[0];
    let tag = params[1];
    let value = null;
    let type = val.type;

    if (field === 'medxat') {
      if (tag === 'uid') {
        return this.userService.getUser().id;
      }
      return this.userService.getUser().profile[tag] || false;
    } else if (field === 'row') {
      value = message[tag];
      if (type && type === 'datetime') {
        let _form = val.format || 'DD/MM/YYYY HH:mm'
        value = moment(value).format(_form);
      }
      return value;
    } else if (field === 'input') {
      //TODO: automatizar segons l'input, ara està forçat a text
      if (tag === 'data') {
        return this.msgValue;
      } else if (tag === 'type') {
        return 'text';
      }
    } else if (field === 'module') {
      if (tag === 'id') {
        return this.module_id;
      }
    } else {
      if (this.item[field]) {
        this.item[field].forEach(element => {
          if (element['tag'] === tag) {
            value = element.value;
            if (type && type === 'datetime') {
              let format = val.format || element.format;
              let _form = format || 'DD/MM/YYYY HH:mm'
              value = moment(value).format(_form);
            }
          }
        });
      }
    }
    return value;
  }

  async _loadBloc(event) {
    this.loading = true;
    if (!event) {
      this.loading = false;
      return;
    }
    try {
      let params = Object.assign({}, event.params);
      var keys = Object.keys(params);
      keys.forEach(key => {
        if (params[key].tag) {
          var tag = params[key].tag;
          params[key] = this.getValue(tag);
        }
      });

      let op = event.op ? event.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : event.event_parent;
      // params.module_id = this.module_id;
      let result = await this.commService.getFunctionPromise(op, params, event_parent)
      if (result && result['data']) {
        this.messages = result['data'];
        if (this.bloc.content)
          this.order = this.bloc.content.order;
        if (this.order === 'desc') {
          this.messages.reverse();
        } else {
          await this.scrollToBottom(300);
        }
        this.messages.forEach(msg => {
          this.updateChatByConditions(msg);
        });

        this.loaded = true;
      } else {
        await this.showErrorAlert();
      }

      this.loading = false;
    } catch (error) {
      await this.showErrorAlert(error.message);
    }
  }

  updateChatByConditions(msg, action?: any, index?) {
    if (!this.bloc || !this.bloc.actions) {
      return;
    }

    if (action && (index || index === 0)) {
      if (action.condition) {
        if (this.getValue(action.condition, msg) === action.condition.value) {
          this.messages[index] = {
            ...this.messages[index],
            ...action.condition.update
          }
        }
      }
      return;
    }

    index = this.messages.indexOf(msg);
    let actions = Object.keys(this.bloc.actions);
    actions.forEach(label => {
      action = this.bloc.actions[label];
      if (action && action.condition) {
        if (this.getValue(action.condition, msg) === action.condition.value) {
          this.messages[index] = {
            ...this.messages[index],
            ...action.condition.update
          }
        }
      }
    });
  }



  messageTextareaKeypress(event: KeyboardEvent, action?) {
    //check platform is mobile to ignore enter, if not (web) --> ignore shift + enter
    if (!this.platform.is('cordova')) {
      if ((event.key === 'Enter') && !event.shiftKey) {
        event.preventDefault();
        this.clickAction(action);
      }
    }
  }

  async showErrorAlert(message?: string) {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    let alert = await this.alertCtrl.create({
      header: 'Error',
      subHeader: message,
      buttons: [{
        text: translations['general'].ok,
        role: 'destructive'
      }]
    });
    this.loading = false;

    return alert.present();
  }

  mobileView() {
    return (this.platform.is('mobile') || this.platform.is('mobileweb')) && !(this.platform.is('tablet') || this.platform.is('ipad'));
  }

  async showToast(message: string = '') {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    if (!message || message === '') {
      message = translations['general'].error_loading;
    } else {
      message = translations['general'].error_loading + " Error " + message;
    }
    const done = await this.toastController.create({
      message,
      duration: 3000,
      position: 'bottom',
    });
    return done.present();
  }

  scrollToBottom(time = 200): void {
    try {
      setTimeout(() => {
        if (this.chatList && this.chatList.nativeElement) {
          this.chatList.nativeElement.scrollTop = this.chatList.nativeElement.scrollHeight;
        }
      }, time);
    } catch (err) {
    }
  }

  getColor(message) {
    if (message) {
      return ColorGenerator.getTextColor(message.creator);
    }
  }

  receiptStatus(message): ReceiptStatus {
    return ReceiptStatusUtil.getMessageStatus(message);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.bloc = null;
  }
}
