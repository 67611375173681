import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileService } from 'src/services/file.service';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'video-thumb',
  templateUrl: './video-thumb.component.html',
  styleUrls: ['./video-thumb.component.scss'],
})
export class VideoThumbComponent {

  @Input() originalWidth: number;
  @Input() originalHeight: number;
  @Input() key?: string;
  @Input() chatId: string;

  videoSrc: string;
  loading = true;
  loaded: any;
  width: number;
  video: any;

  @Input()
  set videoInputData(data: any) {
    this.video = data.video;
    this.videoSrc = this.video.url;
  }

  constructor(public platform: Platform, public fileService: FileService, public chatService: ChatService) { }


  videoLoaded() {
    this.loading = false;
  }

  videoError() {
    this.loading = false;
    this.videoSrc = this.video.thumb;
  }
}
