import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  public auth: boolean;

  constructor(
    public authenticationService: AuthService,
    public router: Router
  ) {
    this.authenticationService.getAuthenticationState().subscribe(val => this.auth = val);

  }

  canActivate(): boolean {
    return this.auth;
  }
}
