import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ChatService } from 'src/services/chat.service';
import { FileService } from 'src/services/file.service';

@Component({
  selector: 'file-thumb',
  templateUrl: './file-thumb.component.html',
  styleUrls: ['./file-thumb.component.scss'],
})
export class FileThumbComponent {

  fileSrc: string;
  nameFile: string;
  sizeFile: string;
  extension: string;
  error: boolean = false;
  @Input() loading: boolean = false;

  @Input() message;
  @Input() chat;

  @Input() position: string;

  @Input()
  set fileInputData(data: any) {
    this.nameFile = data.file.name;
    this.fileSrc = data.file.url;
    this.extension = this.getFileExtension(data.file.name);
    this.fileLoader().then(size => {
      this.sizeFile = size;
    })
  }

  constructor(public platform: Platform, public fileService: FileService, public chatService: ChatService) { }

  async fileLoader() {
    try {
      const data = await this.fileService.getSizeFile(this.fileSrc);
      this.error = false;
      this.loading = false;
      return data;
    } catch (e) {
      console.error("Error FILE-THUMB.COMPONENT.TS: FILE_LOADER", e);
      this.error = true;
    }
  }

  openFileModal() {
    // Lógica para abrir el modal aquí
  }

  getFileExtension(filename: string): string {
    return filename.split('.').pop() || '';
  }
  

}
