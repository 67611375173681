import { registerPlugin } from '@capacitor/core';
import type { PermissionState } from '@capacitor/core';


export interface AudioPlugin {
  recordAudio(): Promise<any>;
  stopAudio(): Promise<any>;
  checkPermissions(): Promise<{record: PermissionState}>;
  requestPermissions(): Promise<{record: PermissionState}>;
}

const AudioPlugin = registerPlugin<AudioPlugin>('AudioPlugin');

export default AudioPlugin;