import { Injectable } from '@angular/core';
import { TimeService } from './time.service';
import { AlertService } from './alert.service';
import { LoadingService } from './loading.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ref, uploadBytes, UploadResult } from "firebase/storage";

import { Directory, DownloadFileResult, Filesystem, FilesystemDirectory, MkdirOptions } from '@capacitor/filesystem';
import FileOpenerPlugin from 'src/capacitor_plugins/file-opener';
import { FirebaseService } from './firebase.service';
import { FileOpener } from '@capacitor-community/file-opener';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import * as pdfjsLib from 'pdfjs-dist';
import { PreviewModalComponent } from 'src/app/shared/preview-modal/preview-modal.component';
import decode from 'heic-decode';
import { Buffer } from 'buffer';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  public FILE_MAX_SIZE = 20971520;
  public FILE_MB_SIZE = 1048576;
  toast;

  public _doctypes = [
    'pdf', '.pdf', 'application/pdf',
    'odt', '.odt', 'txt', '.txt', 'doc', '.doc', 'docx', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls', '.xls', 'xlsx', '.xlsx', 'application/vnd.ms-excel',
    'xml', '.xml', 'application/xml', ".log", "log", "json", ".json",
    'ppt', '.ppt', 'pptx', '.pptx', 'application/vnd.ms-powerpoint'
  ];

  public _pdftypes = ['pdf', '.pdf', 'application/pdf'];

  public _imagetypes = [
    'jpg', 'jpeg', '.jpg', '.jpeg', 'image/jpeg', 'image/jpg',
    'png', '.png', 'image/png',
    'tiff', '.tiff',
    'gif', 'image/gif',
    'image/*', 'image/heic'
  ];

  public _videotypes = [
    'mp4', '.mp4', 'video/mp4',
    'webm', '.webm', 'video/webm',
    'ogg', '.ogg', 'video/ogg',
    'avi', '.avi', 'video/avi',
    'mkv', '.mkv', 'video/mkv',
    'giff', '.giff', 'video/giff',
    'wmv', '.wmv', 'video/wmv',
    'mpg', '.mpg', 'video/mpg',
    'mpeg', '.mpeg', 'video/mpeg',
    'mov', , '.mov', 'video/quicktime'
  ];

  public _audiotypes = [
    'mp3', '.mp3', 'audio/mp3',
    'wav', '.wav', 'audio/wav',
    'webm', '.webm', 'audio/webm',
    'm4p', '.m4p', 'audio/m4p',
    //'octet-stream', 'application/octet-stream',
    'audio/mpeg-3'
  ];

  public imageExtensions = {
    "image/gif": "gif",
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/tiff": "tif",
    "image/vnd.wap.wbmp": "wbmp",
    "image/x-icon": "ico",
    "image/x-jng": "jng",
    "image/x-ms-bmp": "bmp",
    "image/svg+xml": "svg",
    "image/webp": "webp",
    "video/3gpp": "3gpp",
    "video/mpeg": "mpeg",
    "video/quicktime": "mov",
    "video/x-flv": "flv",
    "video/x-mng": "mng",
    "video/x-ms-asf": "asx",
    "video/x-ms-wmv": "wmv",
    "video/x-msvideo": "avi",
    "video/mp4": "mp4"
  }


  public loadedImages: { [k: string]: any } = {};
  public downloadedFiles: { [k: string]: any } = {};
  public alertModal;
  ffmpeg = new FFmpeg();
  baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm";
  message = "";
  loaded = false;

  constructor(
    public fs: FirebaseService,
    public platform: Platform,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public timeService: TimeService,
    public tr: TranslateService,
    public loadingService: LoadingService,
    public http: HttpClient,
    public alertService: AlertService,
    private modalCtrl: ModalController) 
  {
    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js'
  }


  async upload(path: string, fileName: string, file: any, warning = true, voiceMsg = false, chatKey?: any, notLoader?: boolean): Promise<UploadResult> {
    //TODO: fer % pujada
    //https://firebase.google.com/docs/reference/js/firebase.storage.UploadTask
    

    if (typeof file === 'string' || file instanceof String) {
      file = await this.getFileFromLocalURL(file);
      if (!fileName && !file.name) {
        fileName = '' + new Date().getTime() + (file.type ? ('.' + this.imageExtensions[file.type]) : '');
      }
      if (file.name) {
        fileName = file.name;
      }
    }
    if (warning) {
      if (!notLoader) await this.loadingService.dismissLoading();
      if (!file.type.startsWith('image/') && !file.type.startsWith('application/pdf') && !file.type.startsWith('video/') && !file.type.startsWith('audio/') && !voiceMsg) {
        const alert = await this.alertService.showFileAlert(fileName, voiceMsg);
        await alert.present();
        const result = await alert.onDidDismiss();
        if (!result || result.role === 'cancel' || result.role === 'backdrop') { // FILE DISMISSED
          return Promise.reject('dismiss');
        }
      } else if (!file.type.startsWith('audio/') && !voiceMsg && chatKey) {
        const modal = await this.modalCtrl.create({
          component: PreviewModalComponent,
          componentProps: {
            file: file,
            voiceMsg: voiceMsg,
            chatKey: chatKey
          },
          cssClass: this.platform.is('capacitor') ? '' : 'custom-image-gallery-modal',
          showBackdrop: true,
          backdropDismiss: true,
          id: "previewModal"
        });
        await modal.present();
        let message = ''
        const { data } = await modal.onDidDismiss();

        if (data) {
          const { messageWrite, file } = data;
          modal.dismiss();
          message = messageWrite;
        } else {
          return Promise.reject('dismiss');
        }
      }
      
      if (!notLoader) await this.loadingService.presentLoading();
    }
    try {
      if (file && file.size > this.FILE_MAX_SIZE) {
        const translations = await this.tr.get(['documents']).pipe(first()).toPromise();
        await this.alertService.showError(translations.documents.size);
        return null;
      } else if (file) {
        let content: any;
        let blob: any;
        try {
          if (file.type === 'image/heic' || file.type === 'image/heif') {
            const heicData = await file.arrayBuffer();
            const buffer = Buffer.from(new Uint8Array(heicData));
            const { width, height, data } = await decode({ buffer });

            // Create a canvas to draw the decoded image data
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');

            if (context) {
              // Create an ImageData object and put the decoded data on the canvas
              const imageData = new ImageData(new Uint8ClampedArray(data), width, height);
              context.putImageData(imageData, 0, 0);

              // Convert canvas to Blob in JPEG format
              content = await new Promise<Blob>((resolve) =>
                canvas.toBlob((blob) => resolve(blob as Blob), 'image/jpeg')
              );

              blob = new Blob([content], { type: 'image/jpeg' });

              fileName = fileName.replace(/\.(heic|heif)$/i, '.jpeg');
            }
            
          } else {
            content = await file.arrayBuffer();
            blob = new Blob([content], { type: file.type });
          }
        } catch (e) {
          console.error('error decoding file', e);
          if (!notLoader) await this.loadingService.dismissLoading();
          content = file;
        }
        const fileRef = ref(this.fs.storage, `${path}/${this.timeService.now()}/${fileName}`);
        if (!notLoader) await this.loadingService.dismissLoading();
        return uploadBytes(fileRef, blob);
        // return await this.fs.storage.upload(`${path}/${this.timeService.now()}/${fileName}`, file);
      }
    } catch (e) {
      console.error('error uploading', e);
      return null;
    }
  }

  base64ToFile(base64Data: string, filename: string): File {
    const arr = base64Data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  }  

  base64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  
  async base64ToBlob(doc, type) {
    var byteCharacters = atob(doc);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: type });
    
    return file;
  }

  async uploadVideoAndroid(path: string, fileName: string, file: any, warning = true, chatKey?): Promise<UploadResult> {
    //TODO: fer % pujada
    if (warning) {
      console.log("dismissLoading2");
      await this.loadingService.dismissLoading();
      /* const alert = await this.alertService.showFileAlert(fileName, false);
      await alert.present();
      const result = await alert.onDidDismiss();
      if (!result || result.role === 'cancel' || result.role === 'backdrop') { // FILE DISMISSED
        return Promise.reject('dismiss');
      } */
      const modal = await this.modalCtrl.create({
        component: PreviewModalComponent,
        componentProps: {
          file: file,
          voiceMsg: false,
          chatKey: chatKey
        },
        cssClass: this.platform.is('capacitor') ? '' : 'custom-image-gallery-modal',
        showBackdrop: true,
        backdropDismiss: true,
        id: "previewModal"
      });
      await modal.present();
      let message = ''
      const { data } = await modal.onDidDismiss();

      if (data) {
        const { messageWrite, file } = data;
        modal.dismiss();
        message = messageWrite;
      } else {
        return Promise.reject('dismiss');
      }
      console.log("presentLoading2");
      await this.loadingService.presentLoading();
    }
    try {
      if (file && file.size > this.FILE_MAX_SIZE) {
        const translations = await this.tr.get(['documents']).pipe(first()).toPromise();
        await this.alertService.showError(translations.documents.size);
        return null;
      } else if (file) {
        let content: any;
        try {
          content = await file.arrayBuffer();
        } catch (e) {
          content = file;
        }
        const blob = new Blob([content], { type: file.type });
        const fileRef = ref(this.fs.storage, `${path}/${this.timeService.now()}/${fileName}`);
        return uploadBytes(fileRef, blob);
        // return await this.fs.storage.upload(`${path}/${this.timeService.now()}/${fileName}`, file);
      }
    } catch (e) {
      console.log('error uploading', e);
      return null;
    }
  }

  async getFileFromLocalURL(file) {
    return await fetch(file).then(r => r.blob());
  }

  getFileFromExternalURL(url) {

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function (event) {
        const blob = xhr.response;
        resolve(blob);
      };
      xhr.open('GET', url);
      xhr.send();
    });

  }


  async getFileFromFileSystem(file) {
    try {
      return await Filesystem.readFile({
        path: file
      })
    } catch(e) {
      console.error(e);
    }
  }

  async getFileFromFileSystemWithDirectory(file) {
    try {
      const response = await Filesystem.readFile({
        path: 'file://' + file,
      })
      return response;
    } catch(e) {
      console.error(e);
    }
  }

  async readDirectory() {
    return Filesystem.readdir({
      path: '',
      directory: Directory.Cache,
    });``
  }


  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    })
  }


  dataURItoBlob(dataURI, type) {
    return new Promise((resolve, reject) => {

      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type });
      resolve(blob);
    });
  }


  fileIsDownloaded(message) {
    return this.downloadedFiles[message.id];
  }

  isDocument(type) {
    if (!type) return;
    type = type.toLowerCase();
    return this._doctypes.indexOf(type) != -1;

  }

  isOpenable(type: string) {
    if (!type) return;
    type = type.toLowerCase();
    return this.isDocument(type) || this.isVideo(type) || this.isAudio(type);
  }

  isImage(type) {
    if (!type) return;
    type = type.toLowerCase();
    return this._imagetypes.indexOf(type) != -1;

  }

  isVideo(type) {
    if (!type) return;
    type = type.toLowerCase();
    return this._videotypes.indexOf(type) != -1;

  }

  isAudio(type) {
    if (!type) return;
    type = type.toLowerCase();
    return this._audiotypes.indexOf(type) != -1;

  }

  isPdf(type) {
    if (!type) return;
    type = type.toLowerCase();
    return this._pdftypes.indexOf(type) != -1;

  }

  checkFormat(type: string, voice?: boolean) {
    if (type != null) {
      type = type.toLowerCase();
      if (this.isVideo(type) && !voice) {
        return 'video';
      } else if (this.isAudio(type) || voice) {
        return 'audio';
      } else if (this.isImage(type) && !voice) {
        return 'image';
      } else if (this.isPdf(type) && !voice) {
        return 'pdf';
      } else {
        return 'file';
      }
    } return '';
  }

  pushLoadedImage(chatId, msgId, image) {
    if (!this.loadedImages[chatId]) {
      this.loadedImages[chatId] = {};
    }
    if (!this.loadedImages[chatId][msgId])
      this.loadedImages[chatId][msgId] = image;
  }
  imageIsLoaded(chatId, msgId) {
    if (!this.loadedImages) return null;
    if (!this.loadedImages[chatId]) return null;
    if (!this.loadedImages[chatId][msgId]) return null;
    return this.loadedImages[chatId][msgId]
  }

  generateThumbnail(file): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const canvas = document.createElement('canvas');
        const img = new Image();
        const MAX_SIZE = 70;

        const url = URL.createObjectURL(file);
        const cleanup = () => URL.revokeObjectURL(url);
        const onError = () => {
          cleanup();
          reject('Something went wrong');
        };

        img.src = url;

        img.addEventListener('error', onError);
        img.addEventListener('abort', onError);
        img.addEventListener('load', () => {
          if (img.width >= img.height) {
            canvas.width = MAX_SIZE;
            canvas.height = MAX_SIZE * img.height / img.width;
          } else {
            canvas.width = MAX_SIZE * img.width / img.height;
            canvas.height = MAX_SIZE;
          }

          if (canvas.getContext) {
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            const dataURL = canvas.toDataURL('image/jpeg', 0.2);

            if (dataURL) {
              resolve({
                dataURL,
                originalWidth: img.width,
                originalHeight: img.height,
              });
            }
            else {
              reject('Unable to get data URL from context');
            }
          } else {
            reject('Unable to get context');
          }

          cleanup();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  async generatePdfThumbnail(pdfFile: any): Promise<any> {
    const pdf = await pdfjsLib.getDocument(pdfFile).promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 0.5 });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
  
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    
    const croppedCanvas = document.createElement('canvas');
    const croppedContext = croppedCanvas.getContext('2d')!;
    croppedCanvas.width = canvas.width;
    croppedCanvas.height = canvas.height / 4;

    croppedContext.drawImage(canvas, 0, 0, canvas.width, canvas.height / 4, 0, 0, croppedCanvas.width, croppedCanvas.height);

    //const response = await fetch(pdfFile, { mode: 'no-cors' });
    const pdfBlob: any = await this.getFileFromExternalURL(pdfFile);

    const data = {
      url: croppedCanvas.toDataURL(),
      numPages: pdf.numPages,
      size: pdfBlob.size >= 1024 * 1024
        ? (pdfBlob.size / (1024 * 1024)).toFixed(2) + ' MB'
        : (pdfBlob.size / 1024).toFixed(2) + ' KB'
    }

    return data;
  }

  async getSizeFile (url: string) {
    const response = await fetch(url);
    const pdfBlob = await response.blob();
    return pdfBlob.size >= 1024 * 1024
      ? (pdfBlob.size / (1024 * 1024)).toFixed(2) + ' MB'
      : (pdfBlob.size / 1024).toFixed(2) + ' KB';
  }

  public async showDownloadWarning(url: string, name: string, type: string, gallery = false) {
    let translations = [];
    await this.tr.get(['download', 'general']).forEach(tr => translations = tr);
    const dTitles = translations['download'];
    this.alertModal = await this.alertCtrl.create({
      header: dTitles.title,
      message: gallery ? dTitles.message_gallery : dTitles.message,
      buttons: [
        {
          text: translations['general'].cancel,
          role: 'cancel'
        },
        {
          text: translations['general'].yes,
          handler: async () => {
            this.alertModal.dismiss();
            await this.getFilesystemAccess()
            if (this.platform.is('ios')) await FileOpenerPlugin.open({ url, type, name });
            else if (this.platform.is('android')) {
              try {
                console.log("presentLoading3");
                await this.loadingService.presentLoading();
                console.log("SELECT FILE", url, name, type);
                let res = await this.downloadFileToLocal(url, name, type);
                await this.showToast(name, res, type);
                try {
                  await this._openFileWithType(res.path, type);
                } catch (e) {
                  if (this.toast) this.toast.dismiss();
                  console.log("dismissLoading3");
                  await this.loadingService.dismissLoading();
                  await this.alertService.showError(this.tr.instant('download.no_activity', { type }));
                }
              } catch (e) {
                console.log("dismissLoading4");
                await this.loadingService.dismissLoading();
                await this.alertService.showError();
              }
              console.log("dismissLoading5");
              await this.loadingService.dismissLoading();
            }
          }
        }
      ]
    });
    await this.alertModal.present();
  }



  public async showOpenWarning(url: string, name: string, type: string) {
    let translations = [];
    await this.tr.get(['open', 'general']).forEach(tr => translations = tr);
    this.alertModal = await this.alertCtrl.create({
      header: translations['open'].title,
      message: translations['open'].message,
      buttons: [
        {
          text: translations['general'].cancel,
          role: 'cancel'
        },
        {
          text: translations['general'].yes,
          handler: async () => { 
            await this.alertModal.dismiss();
            await this.openFileIos(url, type, name); 
          }
        }
      ]
    });
    await this.alertModal.present();
  }

  async openFileIos(url, type, name) {
    console.log('URL: ', url)
    console.log('TYPE: ', type)
    console.log('NAME: ', name)
    console.log("presentLoading4");
    await this.loadingService.presentLoading();
    try {
      await FileOpenerPlugin.view({ url, type, name });
      console.log("dismissLoading6");
      await this.loadingService.dismissLoading();
    } catch (e) {
      console.log("FileOpenerPlugin ERROR VIEW", e)
      await this.alertService.showError();
      await this.loadingService.dismissLoading();
    }
  }

  async downloadFileToLocal(url, name, type) {
    let res: DownloadFileResult = await Filesystem.downloadFile({
      path: '/' + name,
      url: url,
      directory: Directory.Documents,
      recursive: true
    })
    return res;
  }

  getFilesystemAccess(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const status = await Filesystem.checkPermissions()
      const state = status.publicStorage
      console.log("Filesystem status", status);
      console.log("Filesystem state", state);

      if (state === 'granted') {
        return resolve(true)
      } else if (state === 'denied') {
        // You make want to redirect to the main app settings.
      } else {
        Filesystem.requestPermissions()
      }
      return resolve(false)
    })
  }

  //  Open the file
  private async _openFileWithType(filePath: string, fileType: string) {
    const fileOpenerOptions: any = {
      filePath: filePath,
      contentType: fileType,
    };

    return FileOpener.open(fileOpenerOptions)
  }

  public blobToFile = (blob: Blob, fileName: string): File => {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return blob as File;
  }

  public tableToExcel(table_id, fileName?) {
    if (!fileName) fileName = 'medxat_export' + this.timeService.now() + '.xlsx';

    let element = document.getElementById(table_id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  public excelToJSON(file): Promise<any> {
    return new Promise((resolve, reject) => {
      let a: XLSX.ParsingOptions;
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      // const file = ev.target.files[0];
      reader.onload = (event) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy;@' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet, {
            defval: ''
          });
          return initial;
        }, {});
        let rows = jsonData[Object.keys(jsonData)[0]];
        resolve(rows);
      };
      reader.readAsBinaryString(file);
    })

  }


  async showToast(name: any, file: any, type: any) {
    this.toast = await this.toastController.create({
      message: this.tr.instant('download.doc_downloaded', { name }),
      position: 'top',
      duration: 10000,
      animated: true,
      buttons: [{
        text: this.tr.instant('general.close'),
        role: 'cancel'
      },
      {
        text: this.tr.instant('general.open'),
        handler: async () => {
          try {
            return this._openFileWithType(file.path, type);
          } catch (e) {
            await this.loadingService.dismissLoading();
            await this.alertService.showError(this.tr.instant('download.no_activity', { type }));
          }
        }
      }
      ]
    });
    await this.toast.present();
  }

}
