import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Share } from '@capacitor/share';
import { IonTextarea, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';
import { FileService } from 'src/services/file.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'app-pdf-viewer-modal',
  templateUrl: './pdf-viewer-modal.component.html',
  styleUrls: ['./pdf-viewer-modal.component.scss'],
})
export class PdfViewerModalComponent implements AfterViewInit {

  @Input() chatId: any;
  messageId: any;
  pdfUrl: string;
  pdfName: string;
  safePdfUrl: SafeResourceUrl;
  isCapacitor: boolean;
  message: string;
  maxHeight: any;

  @ViewChild('textArea', { static: false }) textareaRef: IonTextarea;
  @ViewChild('previewContainer', { static: false }) previewContainerRef: ElementRef;

  constructor(
    public platform: Platform,
    public modalCtrl: ModalController,
    public tr: TranslateService,
    public fileService: FileService,
    private sanitizer: DomSanitizer,
    public chatService: ChatService
  ) { }

  @Input()
  set pdfInputData(data: any) {
    if (!this.platform.is('capacitor')) {
      this.isCapacitor = false;
    } else {
      this.isCapacitor = true;
    }
    this.pdfUrl = data.file.url;
    this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl);
    this.messageId = data.id;
    this.pdfName = data.file.name;
    this.message = data.content;
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.maxHeight = this.previewContainerRef.nativeElement.offsetHeight;
    }, 250);
  }

  async dismiss() {
    await this.modalCtrl.dismiss(null, null, "pdfModal");
  }

  async download() {
    if (!this.platform.is('capacitor')) {
      await Browser.open({ url: this.pdfUrl });
    } else {
      await this.fileService.showDownloadWarning(this.pdfUrl, 'document.pdf', 'application/pdf', true);
    }
  }

  async share() {
    let translations = [];
    await this.tr.get(['documents']).forEach(tr => translations = tr);
    let shareRet = await Share.share({
      title: this.pdfName,
      text: this.pdfName,
      url:  this.pdfUrl,
      dialogTitle: translations['documents'].share
    });
  }

  calculateHeights (e: Event) {
    this.chatService?.chatToolbarHeight(e);
    this.adjustPreviewHeight();
  }

  async adjustPreviewHeight() {
    const textarea = await this.textareaRef.getInputElement()
    const textareaHeightString = textarea.style.height;
    const textareaHeight = textareaHeightString ? parseInt(textareaHeightString.replace('px', ''), 10) : textarea.scrollHeight;
    const maxHeight = this.maxHeight;
    const adjustedHeight = maxHeight - textareaHeight + 20;

    if (this.previewContainerRef && this.previewContainerRef.nativeElement && adjustedHeight > 0) {
        this.previewContainerRef.nativeElement.style.height = `${adjustedHeight}px`;
    }
  }

  async triggerInputEvent(): Promise<Event> {
    const textAreaElement = await this.textareaRef.getInputElement();

    return new Promise<Event>((resolve) => {
      const handler = (event: Event) => {
        textAreaElement.removeEventListener('input', handler);
        resolve(event);
      };

      textAreaElement.addEventListener('input', handler);
      textAreaElement.dispatchEvent(new Event('input'));
    });
  }

  async ionViewWillEnter() {
    const event = await this.triggerInputEvent();
    this.calculateHeights(event);
  }

}
