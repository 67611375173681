import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserService } from 'src/services/user.service';
import { firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { NavController, Platform } from '@ionic/angular';
import { CommunicationService } from '../../../services/communication.service';
import { distinctUntilChanged, filter, pluck, switchMap } from 'rxjs/operators';
import { ModulesService } from '../../../services/modules.service';
import { BadgeService } from '../../../services/badge.service';
import { AlertService } from 'src/services/alert.service';
import { NavigationService } from 'src/services/navigation.service';
import { PinService } from 'src/services/pin.service';
import { MenuService } from 'src/services/menu-service';

import { Browser } from '@capacitor/browser';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FirebaseService } from 'src/services/firebase.service';

@Component({
  selector: 'app-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss'],
})
export class MenuContentComponent implements OnDestroy, OnInit{

  public user$: ReplaySubject<any> = this.userService.user$;


  public menuItems = [];



  @Input() contentId: any;
  @Input() menuToggle = true;
  @Input() showOrgs = true;
  @Input() showProfile = true;
  @Input() showTelemedicine = true;
  @Input() showChats = true;
  @Input() showVisits = true;
  @Input() showPatientsDashboard = true;
  // @Input() showSecureCom = true;
  // @Input() showUploadCom = true;
  // @Input() showPublicForm = true;
  @Input() showApps = true;
  @Input() showHomeMenu = true;
  @Input() showFixedApps = true;
  // @Input() showUsersDirectory = true;
  // @Input() showWorkdayRegister = true;

  public loadingModules = false;
  public onDestroy$: Subject<void> = new Subject();
  public org$: Observable<any> = this.userService.org$.pipe(
    filter(org => org),
    distinctUntilChanged((a, b) => a.id === b.id && JSON.stringify(a) === JSON.stringify(b))
  );
  public orgBatches$: Observable<any> = this.badgeService.orgBatches$.pipe(
    filter(x => x),
  );

  constructor(
    public menuService: MenuService,
    public authService: AuthService,
    public userService: UserService,
    public modulesService: ModulesService,
    public communicationService: CommunicationService,
    public badgeService: BadgeService,
    public alertService: AlertService,
    public navigationService: NavigationService,
    public pinService: PinService,
    public navCtrl: NavController,
    public platform: Platform,
    public fs: FirebaseService,
    public http: HttpClient
  ) {
  }
  ngOnInit(){

  }

  executeCommand(command) {
    switch (command) {
      case 'profile':
        this.navCtrl.navigateRoot('profile');
        break;
      case 'logout':
        this.alertService.showLogoutAlert();
        break;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  change($event: CustomEvent<any>) {
    const id = $event.detail.value;
    const org = this.userService.org$.getValue();
    if (org && org.id !== id) {
      this.userService.selectOrganization(id);
    }
  }

  async openMenuInAppBrowser(module) {
    if (module && module.id) {
      const data = await this.modulesService.getModule(module.id);
      const params = { module_id: module.id, log: data.log ? data.log : false };

      try {
        const response = await this.communicationService.getFunctionPromise(data.event, params, data.event_parent);
        if (response && response.external_view && response.external_view.view && response.external_view.view.url) {
          await Browser.open({ url: response.external_view.view.url });
        }
      } catch (e) {
        this.alertService.showError();
      }
    }
  }
}
