// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf {
  padding: 0;
  height: 95%;
}

.send {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 8px 4px rgba(0, 0, 0, 0.1);
}

ion-button {
  --background: transparent;
  --box-shadow: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide {
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

ion-slides {
  background: #000;
  color: #000;
  margin: 0;
  padding: 0;
}

.preview {
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}

.margin-textaerea {
  margin: 20px;
}

ion-textarea {
  border-bottom: 1px solid rgba(144, 140, 140, 0.5);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/preview-modal/preview-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EACA,8CAAA;AACJ;;AAEA;EACI,yBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AACE;;;EAGE,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACE;EACE,gBAAA;AAEJ;;AACE;EACE,WAAA;EACA,YAAA;AAEJ;;AACE;EACE,gBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AAEJ;;AACA;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,iDAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAEF","sourcesContent":[".pdf {\n    padding: 0;\n    height: 95%;\n}\n\n.send {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    background-color: white;\n    box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 10%);\n}\n\nion-button{\n    --background: transparent;\n    --box-shadow: none;\n}\n\n.swiper-zoom-container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n  }\n  .swiper-zoom-container > img,\n  .swiper-zoom-container > svg,\n  .swiper-zoom-container > canvas {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: contain;\n  }\n  \n  .swiper-slide {\n    overflow: hidden;\n  }\n  \n  .swiper-container {\n    width: 100%;\n    height: 100%;\n  }\n  \n  ion-slides {\n    background: #000;\n    color:#000;\n    margin: 0;\n    padding: 0;\n  }\n\n.preview {\n  height: 95%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #252525;\n}\n\n.margin-textaerea {\n  margin: 20px;\n}\n\nion-textarea {\n  border-bottom: 1px solid rgb(144 140 140 / 50%);\n  margin-bottom: 10px;\n  margin-left: 10px;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
