import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent  implements OnInit {

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  public iti;
  private numClone;

  constructor() { }

  ngOnInit() {
    if (this.value)
      this.numClone = `${this.value}`;
    this.init();
  }

  async emit($event) {
    let num = "+" + (this.iti.getSelectedCountryData()).dialCode + $event.target.value;
    this.valueChange.emit(num);
  }

  async init() {
    const inputElement = <HTMLInputElement>document.getElementById('phone');

    if (inputElement) {
      this.iti = intlTelInput(inputElement, {
        separateDialCode: true,
        // nationalMode: false,
        // utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'
        // utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
      });
      this.iti.setNumber(this.numClone);
      // this.telInput.setNumber(this.country);
      // console.log("NUMBER START =>", this.telInput.getNumber());
    }
  }

}
