import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'audio-thumb',
  templateUrl: './audio-thumb.component.html',
  styleUrls: ['./audio-thumb.component.scss'],
})
export class AudioThumbComponent {

  @Input() src: string = ''; 
  @ViewChild('audioPlayer', { static: false }) audioPlayer!: ElementRef;

  isPlaying = false;
  currentTime = 0;
  duration = 0;
  playbackRate = 1;

  ngAfterViewInit() {
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;

    audio.addEventListener('loadedmetadata', () => {
      this.duration = Math.floor(audio.duration * 1000);
    });
  }

  togglePlay() {
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
    if (this.isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  updateProgress() {
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
    this.currentTime = Math.floor(audio.currentTime * 1000);
    this.duration = Math.floor(audio.duration * 1000);
    if (this.currentTime >= this.duration) {
      this.isPlaying = false;
      setTimeout(() => {
        this.currentTime = 0;
      }, 100);
    }
  }

  setTime(event: any) {
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
    const newTimeInMs = event.target.value;
    const newTimeInSeconds = newTimeInMs / 1000;
    audio.currentTime = newTimeInSeconds;
    this.currentTime = newTimeInMs;
  }

  formatTime(time: number): string {
    const minutes: number = Math.floor((time/1000) / 60);
    const seconds: number = Math.floor((time/1000) % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  formatRemainingTime(): string {
    const remainingTime = (this.duration/1000) - (this.currentTime/1000);
    const minutes: number = Math.floor(remainingTime / 60);
    const seconds: number = Math.floor(remainingTime % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  changePlaybackRate() {
    const audio: HTMLAudioElement = this.audioPlayer.nativeElement;
  
    if (this.playbackRate === 1) {
      this.playbackRate = 1.5;
    } else if (this.playbackRate === 1.5) {
      this.playbackRate = 2;
    } else {
      this.playbackRate = 1; 
    }
  
    audio.playbackRate = this.playbackRate;
  }

}
